<template>
  <Spinner :loading="!isProductLoaded" />
  <div class="product-page w-full max-container" v-show="isProductLoaded">
    <div class="product-section">
      <div class="product-info-grid">
        <div class="product-info">
          <div class="product-preview md:sticky top-0 relative">
            <div class="white-block" v-if="isProductLoaded && product.data.product_images.length < 4">
              <div class="close-btn absolute right-0 top-2 md:hidden">
                <button class="cls-btn" tabindex="2" @click="$emit('close')">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <div class="large-preview">
                <img
                  :src="
                    product.data.product_images[activeImage || 0]
                      ? product.data.product_images[activeImage || 0].base_path +
                        '/' +
                        product.data.product_images[activeImage || 0].file_name
                      : product.data.product.image_url
                  "
                  style="cursor: zoom-in;"
                  ref="nonslider"
                  alt="Image description"
                  @click="() => triggerGalleryLightbox(activeImage || 0)"
                />
              </div>
              <div class="preview-grid non-slider">
                <div
                  v-for="(image, idx) in product.data.product_images"
                  :key="image.id"
                  class="preview-img cursor-pointer"
                  alt="Image description"
                  @click="nonGallery(image.base_path + '/' + image.file_name, idx)"
                >
                  <img :src="image.base_path + '/' + image.file_name" :class="{ active: idx === activeImage }" />
                </div>
              </div>
            </div>
            <div class="white-block" v-else-if="isProductLoaded">
              <div class="close-btn absolute right-0 top-2 md:hidden">
                <button class="cls-btn" tabindex="2" @click="$emit('close')">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <div class="flex items-center justify-center -mx-5" @click.once="reloadSlider()">
                <button class="agile-arrow" :class="{ inactive: !isNextTriggered }" @click="$refs.largethumbnail.goToPrev()">
                  <ChevronLeftIcon />
                </button>
                <VueAgile ref="largethumbnail" :throttle-delay="0" :fade="true" @after-change="onSlideChange($event)" class="large-preview">
                  <img
                    v-for="(image, idx) in product.data.product_images"
                    :key="image.id"
                    :src="image.base_path + '/' + image.file_name"
                    @click="() => triggerGalleryLightbox(idx)"
                    style="cursor: zoom-in;"
                  />
                </VueAgile>
                <button class="agile-arrow" @click="$refs.largethumbnail.goToNext(), (isNextTriggered = true)">
                  <ChevronRightIcon />
                </button>
              </div>
              <ImagesGrid :images="product.data.product_images" :current="activeImage" @change="updateCurrentSlide" />
            </div>
          </div>
          <div class="product-desc white-block">
            <div class="prod-header flex items-start justify-between">
              <div>
                <h3 class="text-xl lg:text-3xl font-semibold text-brand-primary mb-1 leading-snug">
                  {{ product.data.product ? product.data.product.name : null }}
                </h3>
                <ol role="list" class="flex items-center flex-wrap gap-1">
                  <li class="flex items-center flex-shrink-0 gap-1">
                    <router-link :to="'/home'"
                      ><div class="text-sm text-brand-secondary text-opacity-60 font-light tracking-wide">
                        Home
                      </div></router-link
                    >
                    <span class="text-sm text-brand-secondary text-opacity-60 font-normal">
                      /
                    </span>
                  </li>
                  <li class="flex items-center flex-shrink-0 gap-1">
                    <router-link :to="slugUrl('brand/' + product.data.brand_slug)">
                      <div class="capitalize text-sm text-brand-secondary text-opacity-60 font-light tracking-wide">
                        {{ product.data.product ? product.data.product.brand : "Brand" }}
                      </div>
                    </router-link>
                    <span class="text-sm text-brand-secondary text-opacity-60 font-normal">
                      /
                    </span>
                  </li>
                  <li>
                    <div class="flex-shrink-0 text-sm text-brand-secondary text-opacity-60 font-light tracking-wide">
                      {{ product.data.product ? product.data.product.name : null }}
                    </div>
                  </li>
                </ol>
              </div>
              <div class="flex items-start">
                <div class="fav-button" v-if="isLoggedIn" @click="addtowatchlist()">
                  <div class="lottie-heart" v-if="product.watchlist !== 0 && isProductLoaded">
                    <Vue3Lottie :animationData="heartJson" :height="55" :width="55" :loop="false" />
                  </div>
                  <button class="favourite" v-else>
                    <svg width="20" height="20" class="w-6 h-6 d-none" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 2.91501C11.09 1.63501 12.76 0.825012 14.5 0.825012C17.58 0.825012 20 3.24501 20 6.32501C20 10.1019 16.6056 13.18 11.4627 17.8435L11.45 17.855L10 19.175L8.55 17.865L8.51052 17.8291C3.38263 13.1693 0 10.0953 0 6.32501C0 3.24501 2.42 0.825012 5.5 0.825012C7.24 0.825012 8.91 1.63501 10 2.91501ZM10 16.475L10.1 16.375C14.86 12.065 18 9.21501 18 6.32501C18 4.32501 16.5 2.82501 14.5 2.82501C12.96 2.82501 11.46 3.81501 10.94 5.18501H9.07C8.54 3.81501 7.04 2.82501 5.5 2.82501C3.5 2.82501 2 4.32501 2 6.32501C2 9.21501 5.14 12.065 9.9 16.375L10 16.475Z"
                        fill="#A4A4A4"
                        fill-opacity="0.54"
                      />
                    </svg>
                  </button>
                </div>
                <div class="close-btn hidden md:flex">
                  <button class="cls-btn" tabindex="2" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="py-8">
              <Listbox v-model="selectedBundle" v-if="isBundlePage">
                <div
                  class="relative flex flex-col md:flex-row md:items-center md:space-x-3 justify-start mt-1 mb-8"
                  v-if="product.data.product?.variants?.length > 1"
                >
                  <span class="block text-md mb-3 md:mb-0">View Bundle:</span>
                  <div class="relative w-full max-w-xs">
                    <ListboxButton
                      class="relative w-full cursor-default rounded text-black font-medium text-sm leading-none bg-theme-primary border border-theme-primary bg-opacity-10 py-2.5 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300"
                    >
                      <div class="flex items-center justify-between">
                        <span class="block truncate">{{ selectedBundle.name }}</span>
                        <span class="bg-theme-primary text-white text-xxs px-2 pt-1 pb-0.5 rounded-full leading-none ">25% OFF</span>
                      </div>
                      <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <SelectorIcon class="h-5 w-5 text-theme-dark" aria-hidden="true" />
                      </span>
                    </ListboxButton>
                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="list-none absolute mt-1 max-h-60 max-w-xs w-full overflow-auto z-50 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          v-slot="{ active, selected }"
                          v-for="variant in product.data.product ? sortVariants(product.data.product.variants, isLoggedIn) : []"
                          :key="variant.id"
                          :value="variant"
                          as="template"
                        >
                          <li
                            :class="[
                              active ? 'bg-gray-100 text-theme-secondary' : 'text-theme-secondary',
                              'relative cursor-default select-none py-2 px-4',
                            ]"
                          >
                            <div class="flex items-center justify-between">
                              <span :class="[selected ? 'font-normal' : 'font-normal', 'block truncate font-normal']">{{
                                variant.name
                              }}</span>
                              <span
                                class="bg-brand-indigo bg-opacity-50 text-brand-indigodarkest text-tiny px-2 pt-1 pb-0.5 rounded-full leading-none "
                                >Save 25%</span
                              >
                            </div>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </div>
              </Listbox>
              <div
                class="prod-text prose max-w-none font-light text-brand-primary text-left"
                id="prodDesc"
                :class="{ 'max-prod-height': showReadMore }"
              >
                <div v-if="selectedBundle.id && isBundlePage" class=" text-theme-dark font-medium text-sm">
                  <BundleItems :items="selectedBundle.kits_variants">
                    <template #header>
                      <div class="bundle-title">
                        <p v-if="selectedBundle.id">
                          Bundle Contents ({{ selectedBundle.kits_variants.length }}
                          Products)
                        </p>
                      </div>
                      <div class="bundle-title save-label">
                        <Icon name="info" class="w-3.5 h-3.5" />
                        <span>Save 25% by buying this as a bundle</span>
                      </div>
                    </template>
                  </BundleItems>
                </div>
                <p v-html="product.data.product?.description?.replace(/<a[^>]*>(.*?)<\/a>/g, '$1')"></p>
              </div>
            </div>
            <div class="readmore-block" v-if="showReadMore" @click="showReadMore = false">
              <button>Read More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, nextTick, watch, onMounted } from "vue";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/solid";
import Product from "@/modules/Product";
import ImagesGrid from "@/views/pages/product/modules/ImagesGrid.vue";
import clone from "lodash/clone";
import { VueAgile } from "vue-agile";
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
const heartJson = require("@/assets/lottie/heart.json");
import Mymixin from "@/modules/mixins";
import { isMobile } from "is-mobile";
import BundleItems from "@/views/pages/product/modules/BundleItems.vue";
import { useRoute } from "vue-router";
import { SelectorIcon } from "@heroicons/vue/solid";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { useStore } from "vuex";

export default {
  components: {
    VueAgile,
    ChevronRightIcon,
    ChevronLeftIcon,
    ImagesGrid,
    Vue3Lottie,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    SelectorIcon,
    BundleItems,
  },
  name: "Product",
  mixins: [Mymixin],
  props: ["productSlug"],
  inheritAttrs: false,
  emits: ["clearFilters", "close"],
  data() {
    return {
      first_landed: false,
      newArrival: false,
      br: { name: "Brand", path: "/brands" },
      galleryCurrentIndex: null,
      showGalleryLightbox: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.meta.breadCrumbs) {
        vm.br = {
          name: from.name ?? from.params.slug.replace(/-/g, " "),
          path: from.path,
        };
      }
    });
  },
  // beforeMount() {
  //   this.$store.dispatch("getCart", { ax: null });
  // },
  methods: {
    moment: function(date) {
      return moment(date);
    },
    checkFirstLanded(pro) {
      if (pro) {
        const variants = pro.variants;
        if (variants.length > 0) {
          variants.forEach((el) => {
            const ld = el.first_landed_date;
            const exD = ld ? new Date(ld.first_landed_date) : null;
            const d = new Date();
            d.setDate(d.getDate() - 5);
            // console.log(d, "====", exD, d<exD)
            if (d < exD) this.first_landed = true;
          });
          return false;
        } else return false;
      } else return false;
    },
    triggerGalleryLightbox(index) {
      this.galleryCurrentIndex = index;
      this.showGalleryLightbox = true;
    },
    closeGalleryLightbox() {
      this.showGalleryLightbox = false;
    },
  },
  setup(props) {
    // const isFavourite = ref(false);
    const showReadMore = ref(false);
    const showBulkPricing = ref(false);
    const showOrderInfo = ref(false);
    const largethumbnail = ref();
    const thumbnails = ref();
    const main_Image = ref(null);
    const showAllComps = ref(false);
    const activeImage = ref(0);
    const isNextTriggered = ref(false);
    const nonslider = ref(null);
    const route = useRoute();
    const store = useStore();
    const getBrandImg = (image) => {
      if (image == null) {
        return "/images/no-image.png";
      }
      return "https://d3k6t6l60lmqbi.cloudfront.net/" + product.data.product.product_brand.image.path;
    };
    const slugUrl = (slug) => {
      const base_url = process.env.BASE_URL || "/";
      return base_url + slug;
    };
    const checkInfoHeight = () => {
      const el = document.querySelector("#prodDesc");
      if (!el) return;
      nextTick(() => {
        showReadMore.value = el.clientHeight > 600;
      });
    };
    const isMobDevice = ref(false);
    onMounted(() => {
      isMobDevice.value = isMobile();
    });
    const isBundlePage = computed(() => route.name == "ProductBundles");
    const selectedBundle = ref({
      name: "Select Bundle",
    });
    const {
      product,
      getProduct,
      compatible,
      getCompatibleproducts,
      bulk,
      getproductBulkDiscount,
      addtowatchlist,
      watchlist,
      removefromwatchlist,
      isLoggedIn,
    } = Product(props.productSlug);
    const getAllCompitables = computed(() => {
      if (showAllComps.value) {
        return compatible.data ?? [];
      }
      return clone(compatible.data ?? []).splice(0, 3);
    });

    if(store.getters.getProduct?.data?.product?.slug != props.productSlug){
      getProduct();
      getCompatibleproducts();
    } else {
      product.data = store.getters.getProduct.data;
      product.watchlist = store.getters.getProduct.watchlist;
    }

    const isProductLoaded = computed(() => {
      if (!product.loading) {
        checkInfoHeight();
        return true;
      }
      return false;
    });
    const getGalleryImages = computed(() => {
      if (product.loading || !product.data?.product_images.length) {
        return [];
      }
      return product.data.product_images.map((prodImg) => {
        const img = {
          src: `${prodImg.base_path}/${prodImg.file_name}`,
          file_name: prodImg.file_name,
          title: prodImg.name,
          alt: prodImg.name,
        };
        // TODO : Add Dynamic variants here
        return img;
      });
    });
    const imageClick = (varImg, i = "", index) => {
      const selectedVariant = product.data.product?.variants.find((v) => v.id == i);
      if (selectedVariant.id) {
        selectedBundle.value = selectedVariant;
      }
      const imageFileName = varImg
        .split("/")
        .pop()
        .replace("thumbnail_", "");
      const clickIndex = getGalleryImages.value.findIndex((img) => img.file_name === imageFileName);
      if (product.data.product_images.length < 4) {
        nonGallery(getGalleryImages.value[clickIndex].src, clickIndex);
        return;
      }
      if (clickIndex !== -1) {
        updateCurrentSlide(clickIndex);
      }
    };
    const nonGallery = (url, idx) => {
      activeImage.value = idx;
      if (nonslider.value) {
        nonslider.value.src = url;
      }
    };
    const reloadSlider = () => {
      largethumbnail?.value?.reload();
    };
    const onSlideChange = (e) => {
      activeImage.value = e.currentSlide;
    };
    const updateCurrentSlide = (index) => {
      activeImage.value = index;
      isNextTriggered.value = true;
      largethumbnail.value.goTo(index);
      reloadSlider();
    };
    watch(isProductLoaded, () => {
      setTimeout(() => {
        reloadSlider();
      }, 500);
      const selectedVariant = product.data.product?.variants[0];
      if (selectedVariant.id) {
        selectedBundle.value = selectedVariant;
      }
    });
    return {
      largethumbnail,
      thumbnails,
      product,
      compatible,
      getCompatibleproducts,
      bulk,
      getproductBulkDiscount,
      addtowatchlist,
      watchlist,
      removefromwatchlist,
      // isFavourite,
      isProductLoaded,
      showReadMore,
      showBulkPricing,
      slugUrl,
      isLoggedIn,
      getBrandImg,
      main_Image,
      imageClick,
      reloadSlider,
      getAllCompitables,
      showAllComps,
      onSlideChange,
      activeImage,
      updateCurrentSlide,
      isNextTriggered,
      heartJson,
      showOrderInfo,
      getGalleryImages,
      isMobDevice,
      nonGallery,
      nonslider,
      isBundlePage,
      selectedBundle,
    };
  },
};
</script>
<style lang="scss" scoped>
.product-page {
  @apply py-6 md:pt-0;
  .white-block {
    @apply bg-white rounded-lg shadow-box p-6;
  }
  .product-section {
    @apply flex flex-col 2xl:flex-row gap-6;
    .product-info-grid {
      @apply flex flex-col flex-grow gap-6;
      .product-info {
        @apply w-full items-start flex flex-col md:flex-row gap-6;
      }
      .product-preview {
        @apply w-full md:w-60 lg:w-72 flex-shrink-0 md:space-y-8;
        .large-preview {
          @apply flex items-center justify-center pb-5;
        }
        .preview-grid {
          &.non-slider {
            @apply w-full grid grid-cols-6 2xl:grid-cols-4 gap-3;
          }
          @apply w-full;
          .preview-img {
            @apply flex items-center justify-center;
            img {
              @apply rounded border border-gray-200 max-w-full cursor-pointer;
              &:hover,
              &.active {
                border-color: #7ab1e4;
              }
            }
          }
        }
      }
      .product-category-cards {
        @apply grid grid-cols-1 xl:grid-cols-2 gap-6;
      }
    }
    .product-extra {
      @apply w-full md:w-96 p-0 md:pr-6 md:m-0 md:p-0 flex-shrink-0 space-y-8;
    }
    .product-desc {
      @apply w-full md:w-auto flex-grow py-6 px-8;
    }
    .prod-brand,
    .prod-variants-cart,
    .prod-compitables {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      @apply rounded-lg bg-white overflow-hidden;
    }
  }
  .max-prod-height {
    max-height: 600px;
    overflow: hidden;
  }
  .readmore-block {
    @apply relative flex items-end justify-center w-full h-16 bg-gradient-to-t from-white -mt-16 z-20;
    button {
      @apply bg-white text-theme-body-light border border-theme-border rounded-md leading-none px-6 py-2;
    }
  }
  .fav-button {
    @apply relative cursor-pointer;
  }
  .lottie-heart {
    @apply -mr-5 -mt-3.5;
  }
  .favourite {
    @apply -mr-1;
    margin-top: 3px;
  }
  .close-btn {
    @apply items-center justify-end w-10 -mr-2 pt-1;
  }
  .cls-btn {
    @apply flex items-center justify-end outline-none focus:outline-none ring-0 focus:ring-transparent w-5 h-5 bg-white rounded-full text-theme-border hover:text-brand-secondary;
    svg {
      @apply w-5 h-5;
    }
  }
}
</style>
<style lang="scss">
.vel-img-title {
  @apply bg-black bg-opacity-90 text-white px-5 py-1.5 rounded text-base font-light;
}
.bundle-title {
  @apply flex items-center justify-between px-3 py-1.5 m-0 font-medium border-b border-brand-ash;
  &.save-label {
    @apply border-0 bg-brand-indigo bg-opacity-50 text-brand-indigodarkest font-normal text-xs flex items-center space-x-1.5 justify-start;
  }
  p {
    @apply text-black text-sm p-0 m-0;
  }
}
</style>
