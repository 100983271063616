<script setup>
import { computed, watch, inject } from "vue";
import ProductInfo from "./ProductInfo.vue";
import VariantInfo from "./VariantInfo.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Auth from "@/modules/Auth";
import debounce from "lodash/debounce";
import Notifications from "@/modules/Notifications";
import Watchlist from "@/modules/Watchlist";

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  product: {
    type: Object,
    required: false,
  },
  variant: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(["onProductClick"]);

const qty = ref(0);
const preQty = ref(0);
const localProduct = ref({ ...props.product })

const store = useStore();
const route = useRoute();
const axios = inject("axios");

const cart = computed(() => store.getters.cart);
const { isLoggedIn } = Auth();

// watch cart on mountCart immidiate and deep
watch(cart, mountCart, { immediate: true, deep: true });

watch(
  () => store.getters.getProduct,
  (newVal) => {
    if(newVal.data?.product?.id == props.product.id) {
      localProduct.value.watchlist = newVal.watchlist;
    }
  },
  { deep: true }
);

function mountCart() {
  if (isLoggedIn) {
    if (cart.value.itemsByCat?.length > 0) {
      cart.value.itemsByCat.forEach((element) => {
        element.items.forEach((item) => {
          if (item.variantId == props.variant.id) {
            qty.value = item.qty;
            preQty.value = item.qty;
          }
        });
      });

      // For Update Variant When Any Item Deleted From Cart
      if (cart.value.deleted_variant_id == props.variant.id) {
        qty.value = 0;
      }
    } else {
      qty.value = 0;
    }
  }
}

// Update Cart Data in Store
const updateCart = debounce((d) => {
  let [qty, add] = d;
  let data = {
    variant_id: props.variant.id,
    qty: qty,
    segmentQty: add ? qty - preQty.value : preQty.value - qty,
    axios: axios,
    route: route,
    add,
  };
  preQty.value = qty;
  if (qty === 0) {
    store.dispatch("removeFromCart", data);
  } else {
    store.dispatch("UpdateCart", data);
  }
}, 200);

const { notifyme, deleteNotify } = Notifications();

const notify = (id) => {
  notifyme(id, props.product);
  let idd = id.id;
  props.product.variants.forEach((item) => {
    if (item.id == idd) {
      item.has_notification = true;
    }
  });
};

const doNotNotify = (id) => {
  deleteNotify(id, props.product);
  let idd = id.id;
  props.product.variants.forEach((item) => {
    if (item.id == idd) {
      item.has_notification = null;
    }
  });
};

const lastOrdered = computed(() => {
  if (!props.variant.last_ordered) return "-";
  return moment(props.variant.last_ordered, "YYYYMMDD").fromNow();
});

// Watchlist
const { setWatchlist } = Watchlist();
const addtowatchlist = async () => {
  await setWatchlist(localProduct.value);
  localProduct.value.watchlist = localProduct.value.watchlist ? null : 1;
};
</script>

<template>
  <tr>
    <ProductInfo
      width="280"
      class="px-5 py-3"
      v-bind="
        index == 0
          ? {
              name: product.name,
              variantType: product.opt1 || '',
              watchlist: localProduct.watchlist || null,
            }
          : null
      "
      @onProductInfoTrigger="$emit('onProductClick', product.slug)"
      @onWatchlistUpdate="addtowatchlist"
    />
    <VariantInfo class="px-5 py-3" :variant="variant" @onProductClick="$emit('onProductClick', product.slug)" />
    <td class="px-5 py-3" width="200">
      <p class="text-sm text-theme-secondary text-opacity-70 flex-grow">${{ Number(variant.price).toFixed(2) }}</p>
    </td>
    <td class="px-5 py-3" width="200">
      <p class="text-sm text-theme-secondary flex-grow">${{ Number(variant.wholesale_price).toFixed(2) }}</p>
    </td>
    <td class="px-5 py-3" width="200">
      <div class="flex items-center gap-3">
        <span class="text-sm text-theme-body inline-block flex-shrink-0">{{ lastOrdered }}</span>
        <span
          class="text-xs font-normal min-w-0 flex-grow-0 inline-flex text-center items-center justify-center w-auto rounded-full px-5 py-2 leading-none"
          style="background: rgba(156, 228, 144, 0.31); color: #41b871;"
          v-if="variant.isNew"
        >
          <svg
            class=" w-3.5 h-3.5 flex-shrink-0 mr-1"
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.95521 6.1193H0.865658L3.79103 0.268555V3.61184H5.88058L2.95521 9.46258V6.1193Z" fill="#41B871" />
          </svg>
          New
        </span>
      </div>
    </td>
    <td class="px-5 py-3" width="200">
      <div class="flex items-center gap-3 justify-end">
        <div class="flex justify-end" style="min-width: 150px;">
          <PlusMinusInput
            v-if="variant.available_stock > 0 && variant.banned == 'false' && variant.price != null && variant.price != 0"
            class="medium"
            v-model:modelValue="qty"
            :tickIcon="true"
            v-on:getQuantity="updateCart"
            :min="0"
            :max="variant.MaxOrderQty > variant.available_stock ? variant.available_stock : variant.MaxOrderQty"
          />
          <button
            v-else-if="variant.has_notification"
            :id="variant.id"
            @click="doNotNotify(variant)"
            class="notify-bell "
            :class="{ undo: true }"
          >
            <span class="hidden md:inline-block uppercase text-tiny font-normal text-brand-gray tracking-wider">Notification Set</span>
            <div
              class="n-icon bg-black"
              v-tippy="{
                content: 'You will be notified when this product is available.',
                placement: 'bottom-end',
              }"
            >
              <Icon name="bell-off" class="w-4 h-4" />
            </div>
          </button>
          <button v-else @click="notify(variant)" :id="variant.id" class="notify-bell">
            <span class="hidden md:inline-block uppercase text-tiny font-normal text-brand-gray tracking-wider"></span>
            <div
              class="n-icon text-brand-gray"
              v-tippy="{
                content: 'Click here to be notified when this product is available.',
                placement: 'bottom-end',
              }"
            >
              <Icon name="bell-ring" />
            </div>
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>

<style lang="scss">
.notify-bell {
  @apply flex items-center space-x-3 uppercase text-xs tracking-tight;
  .n-icon {
    @apply inline-flex items-center justify-center w-8 h-8 bg-brand-red text-white rounded;

    svg {
      @apply w-4 h-4;
    }
  }
  &.undo {
    .n-icon {
      @apply bg-theme-secondary;
    }
  }
}
</style>
