<template>
  <div class="flex flex-col items-stretch bg-body min-h-screen bg-theme-bg">
    <Navbar class="navbar-wrapper" />
    <div class="flex items-stretch w-full min-h-screen">
      <Sidebar />
      <div class="contents">
        <Toast />
        <div class="inner-wrapper">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>
    <Footer class="pb-20 sm:pb-0" />
  </div>
</template>

<script>
import Navbar from "./modules/Navbar.vue";
import Sidebar from "./modules/Sidebar.vue";
import Footer from "./modules/Footer.vue";
import Auth from "@/modules/Auth";

export default {
  name: "DashboardLayout",
  components: {
    Navbar,
    Sidebar,
    Footer,
    // MobileNav,
  },
  mounted() {
    this.$store.dispatch("getCart", { ax: null });
  },
  setup() {
    const { verifyUser } = Auth(); 
    verifyUser();
  },
};
</script>

<style lang="scss" scoped>
.contents {
  @apply block h-full flex-grow pt-12 mx-auto overflow-x-hidden;
  max-width: 100vw;

  @media (min-width: 768px) {
    max-width: 80%;
    max-width: calc(100vw - 16rem);
  }

  @media (min-width: 1024px) {
    max-width: calc(100vw - 18rem);
  }

  .inner-wrapper {
    @apply px-4 md:px-8 pt-20 lg:pt-10 overflow-hidden;
  }
}
.navbar-wrapper {
  z-index: 200;
}
</style>
