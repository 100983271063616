import { inject, reactive } from 'vue';
import Segments from './Segments';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
const { ProductViewed,ProductViewedNonLoggedIn } = Segments();

export default function Product(productSlug = null) {
    
    let product_id;
    const axios = inject('axios');
    const store = useStore();

    const product = reactive({
        loading: false,
        data: [],
        error: '',
        watchlist: 0
    })
    const route = useRoute();
    const router = useRouter();
    
    const getProduct = async() => {
        if (axios.isLoggedIn) {
            product.loading = true;
            let slug = productSlug ? productSlug : route.params.slug;

            axios.authApi.get('/product/' + slug).then((resp) => {
                if (resp.status) {
                    if(!resp.data.data){
                        return router.push({ name: '404' });
                    }
                    product.data = resp.data.data;
                    document.getElementsByTagName("title")[0].innerHTML = `${product.data.product.name} - ${product.data.product.brand} - Wholesale`;
                    product_id = product.data.product.id;
                    product.watchlist = product.data.product.watchlist ? 1 : 0;
                    store.dispatch("setProduct", product);
                    ProductViewed(route,product.data);
                } else {
                    product.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (product.loading = false));
        } else {
            product.loading = true;
            let slug = route.params.slug;

            axios.api.get('/products/' + slug).then((resp) => {
                if (resp.status) {
                    product.data = resp.data.data;
                    // product_id = product.data.product.id;
                    ProductViewedNonLoggedIn(route,product.data);
                } else {
                    product.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (product.loading = false));
        }
    };
    const compatible = reactive({
        loading: false,
        data: [],
        error: ''
    })
    const getCompatibleproducts = async() => {
        if (axios.isLoggedIn) {
            compatible.loading = true;
            let slug = productSlug ? productSlug : route.params.slug;

            axios.authApi.get('/compatible-products/' + slug).then((resp) => {
                if (resp.status) {
                    compatible.data = resp.data.data;
                } else {
                    compatible.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (compatible.loading = false));
        } else {}
    };

    const addtowatchlist = async() => {
        if(product.watchlist == 0){
            axios.authApi.post('/add-watchlist', {
                product_id: product_id
            }).then((resp) => {
                if (resp.status) {
                    product.watchlist = 1;
                    store.dispatch("setProduct", product);
                }
            }).catch(function(error) {
                console.log(error);
            });
        } else {
            axios.authApi.get('/remove-watchlist', {
                params: {
                    product_id: product_id
                }
            }).then((resp) => {
                if (resp.status) {
                    product.watchlist = 0;
                    store.dispatch("setProduct", product);
                }
            }).catch(function(error) {
                console.log(error);
            });
        }

    };

    return {
        product,
        getProduct,
        compatible,
        getCompatibleproducts,
        addtowatchlist,
        isLoggedIn: axios.isLoggedIn,
    }

}