<template>
  <div>
    <div class="flex items-center space-x-4 mb-6">
      <h3 class="text-xl font-semibold text-theme-secondary leading-none">
        Shipping Locations
      </h3>
      <button class="card-btn flex items-center -mb-1" @click="show">
        <Icon name="plus" />
        <span>ADD A LOCATION</span>
      </button>
    </div>
    <Spinner :loading="addresses.loading" />
    <div class="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-6">
      <div
        v-for="loc in addresses.data"
        :key="loc.city"
        class="bg-white rounded shadow-box px-5 py-6 flex items-start"
      >
        <div
          class="flex items-center justify-center flex-shrink-0 bg-theme-purple-light text-theme-purple-dark w-10 h-10 rounded-full"
        >
          <Icon name="shop" class="w-6 h-6" />
        </div>
        <div class="flex-grow pl-5">
          <div class="flex items-start justify-between">
            <div class="block w-10/12">
              <!-- <span v-if="loc.nickname" class="block text-sm text-theme-body leading-6 font-light"
                >{{ loc.nickname }}<br />
              </span> -->
              <p class="block text-md text-theme-secondary font-semibold">
                {{ loc.nickname ? loc.nickname : loc.company_name }}
              </p>
              <span class="block text-sm text-theme-body leading-6 font-light"
                >{{ loc.address1 }}<br />
                <span v-if="loc.address2">{{ loc.address2 }}<br /></span>
                {{ loc.city }}, {{ loc.state }} {{ loc.zip_code }}</span
              >
            </div>
            <Menu as="div" class="relative text-right">
              <div>
                <MenuButton class="options-btn">
                  <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <!-- "updateAddressModal = true" -->
                <MenuItems class="options-list">
                  <MenuItem
                    class="cursor-pointer"
                    @click="edit(loc.trade_gecko_id, loc.nickname)"
                  >
                    <a class="options-item">Edit Location</a>
                  </MenuItem>
                  <MenuItem
                    class="cursor-pointer"
                    @click="delAddress(loc.trade_gecko_id)"
                  >
                    <a class="options-item">Delete Location</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div
            v-if="loc.is_expiring"
            class="flex items-center justify-between text-xs font-medium pt-5 pr-5"
          >
            <span
              class="inline-flex items-center px-4 py-0.5 rounded-full text-tiny font-semibold uppercase text-red-800 bg-red-200"
              >{{ getMessage(loc) }}</span
            >
          </div>
          <div
            v-else-if="loc.is_approved == 1"
            class="flex items-center justify-between text-xs font-medium pt-5 pr-5"
          >
            <span
              class="inline-flex items-center px-4 py-0.5 rounded-full text-tiny font-semibold uppercase bg-theme-green-light text-theme-green-dark"
              >APPROVED</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Address modal -->
    <ModalFull
      :is-visible="showAddressModal"
      :reset-container="true"
      @close="(showAddressModal = false), (isManualAddress = false)"
    >
      <Block
        v-show="!showTaxStep && !showTaxDownload"
        icon="shop"
        title="Add New Location"
        :streachActions="true"
        class="modal wd-50"
      >
        <template #action>
          <div
            class="flex-grow flex items-stretch justify-between h-12 -my-4 pl-5"
          >
            <div class="steps flex items-stretch space-x-4">
              <button
                class="font-medium text-sm uppercase border-b-2 border-transparent px-1"
                :class="
                  currentStep === 1 ? 'border-black text-black' : 'cursor-auto'
                "
                v-if="currentStep < 3 && currentStep != 1.2"
              >
                <!-- hover:border-black hover:text-black -->
                <!-- @click="currentStep = 1" -->
                Step 1
              </button>
              <button
                class="font-medium text-sm uppercase border-b-2 border-transparent px-1"
                :class="
                  currentStep === 2 ? 'border-black text-black' : 'cursor-auto'
                "
                v-if="currentStep < 3 && currentStep != 1.2"
                :disabled="isDisabled"
              >
                <!-- @click="currentStep = 2" -->
                Step 2
              </button>
            </div>
            <button
              class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
              @click="
                (showAddressModal = false && currentStep === 1),
                  (isManualAddress = false),
                  (visibility = false),
                  reset()
              "
            >
              <XIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
        <div>
          <div class="grid grid-cols-2 gap-8" v-show="currentStep === 1">
            <div class="col-span-2 input">
              <Input
                v-model:modelValue="address.nickname"
                @keyup="checkValue()"
                placeholder="Location Name"
                maxlength="32"
              >
                <div class="flex items-center w-full space-x-2">
                  <label for="">Location Name</label>
                  <tippy interactive>
                    <span class="cursor-pointer">
                      <Icon
                        name="info"
                        class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                      />
                    </span>
                    <template #content>
                      <p class="text-left px-1.5 text-xs">
                        A location name will change the name of your location on
                        the shipping label. This is helpful when you have a
                        company name but stores with different names.
                        <br />
                        By default, if you don't enter a location name,
                        <b>{{ company_name }}</b> will be the location on the
                        shipping label.
                      </p>
                    </template>
                  </tippy>
                </div>
              </Input>
            </div>
            <template v-if="!isManualAddress">
              <div class="col-span-2 input">
                <Combobox>
                  <div class="relative mt-1">
                    <div
                      class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                    >
                      <ComboboxLabel>Enter Address</ComboboxLabel>
                      <ComboboxInput
                        class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                        :displayValue="(adr) => adr.suggestion.streetLine"
                        @change="query = $event.target.value"
                        placeholder="Type your address"
                      />
                    </div>
                    <TransitionRoot
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      @after-leave="query = ''"
                    >
                      <ComboboxOptions
                        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                      >
                        <div
                          v-if="
                            addresses.suggestions.length === 0 && query !== ''
                          "
                          class="relative cursor-default select-none py-2 px-4 text-gray-700"
                        >
                          No Results found!
                        </div>

                        <ComboboxOption
                          v-for="adr in addresses.suggestions"
                          as="template"
                          :key="adr"
                          :value="adr"
                          v-slot="{ selected, active }"
                        >
                          <li
                            class="relative cursor-default select-none py-2 pl-10 pr-4 text-left hover:bg-gray-50 "
                            :class="{
                              'bg-gray-50 text-theme-secondary': active,
                              ' text-theme-body': !active,
                            }"
                            @click="selectAddress(adr.suggestion)"
                          >
                            <span
                              class="block truncate"
                              :class="{
                                'font-medium': selected,
                                'font-normal': !selected,
                              }"
                            >
                              {{ adr.address }}
                            </span>
                            <span
                              class="absolute inset-y-0 left-0 flex items-center pl-3"
                              :class="{
                                'bg-gray-50 text-theme-secondary': active,
                                ' text-theme-body': !active,
                              }"
                            >
                              <CheckIcon
                                class="h-5 w-5"
                                aria-hidden="true"
                                v-if="selected"
                              />
                              <LocationMarkerIcon
                                class="h-5 w-5"
                                aria-hidden="true"
                                v-else
                              />
                            </span>
                          </li>
                        </ComboboxOption>
                      </ComboboxOptions>
                    </TransitionRoot>
                  </div>
                </Combobox>
                <div
                  class="py-2 text-sm font-light text-brand-body text-left"
                  :class="visibility ? 'hidden' : 'block'"
                >
                  <span>Or </span>
                  <span
                    class="hover:text-brand-primary cursor-pointer underline "
                    @click="isManualAddress = true"
                    >Enter Manually</span
                  >
                </div>
              </div>
              <div
                class="col-span-2 input"
                :class="visibility ? 'visible' : 'invisible'"
              >
                <Input
                  label="Address Line 2"
                  v-model:modelValue="address.address2"
                  placeholder="Address Line 2"
                />
              </div>
              <div
                class="col-span-2 grid grid-cols-3 gap-8"
                :class="visibility ? 'visible' : 'invisible'"
              >
                <div class="input">
                  <Input
                    label="City"
                    v-model:modelValue="address.city"
                    placeholder="City"
                  />
                  <span
                    v-if="isSubmit && v$.address.city.$silentErrors.length > 0"
                    class="flex text-left text-sm font-light text-red-400 pt-2"
                  >
                    <span
                      v-if="
                        v$.address.city.$silentErrors[0].$validator ==
                          'required'
                      "
                      >City is required.</span
                    >
                  </span>
                </div>

                <div>
                  <Listbox
                    as="div"
                    v-model="address.state"
                    class="store-switcher"
                  >
                    <ListboxLabel>State</ListboxLabel>
                    <ListboxButton class="group ss-btn">
                      <span class="flex w-full justify-between items-center">
                        <span
                          class="flex min-w-0 items-center justify-between space-x-3"
                        >
                          <div
                            class="flex items-center justify-center rounded-full w-8 h-8 text-brand-indigo bg-purple-100 flex-shrink-0 order-bg"
                          >
                            <Icon name="shop" class="w-5 h-5" />
                          </div>
                          <span
                            class="flex-1 flex flex-col min-w-0"
                            v-if="address.state"
                          >
                            <span
                              class="text-brand-secondary text-sm font-medium truncate"
                              >{{
                                typeof address.state == "string"
                                  ? selectedState
                                  : address.state.state_name
                              }}</span
                            >
                          </span>
                        </span>
                        <SelectorIcon
                          class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <ListboxOptions
                        class="item-opts"
                        style="height:110px; overflow:auto;"
                      >
                        <ListboxOption
                          as="div"
                          v-for="opt in st.data"
                          :key="opt.id"
                          :value="opt"
                          @click="selectZipcode(opt.state)"
                          class="group item-opt"
                        >
                          <span
                            class="flex min-w-0 items-center justify-between space-x-3"
                          >
                            <span class="flex-1 flex flex-col min-w-0">
                              <span class="name"
                                >{{ opt.state_name
                                }}{{ " (" + opt.state + ")" }}</span
                              >
                            </span>
                          </span>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </Listbox>
                  <span
                    v-if="isSubmit && v$.address.state.$silentErrors.length > 0"
                    class="flex text-left text-sm font-light text-red-400 pt-2"
                  >
                    <span
                      v-if="
                        v$.address.state.$silentErrors[0].$validator ==
                          'required'
                      "
                      >State is required.</span
                    >
                  </span>
                </div>

                <div>
                  <Combobox
                    :disabled="!address.state"
                    v-model="address.zip_code"
                    :value="address.zip_code ? address.zip_code : ''"
                    class="store-switcher"
                    nullable
                  >
                    <div class="relative mt-1">
                      <ComboboxLabel>Zip Code</ComboboxLabel>
                      <div
                        class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                      >
                        <ComboboxInput
                          :disabled="!address.state"
                          class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                          :displayValue="
                            (zip) =>
                              typeof address.zip_code == 'string'
                                ? address.zip_code
                                : zip?.zip_code
                          "
                          @change="
                            getZipcodes(address.state, $event.target.value)
                          "
                        />
                        <ComboboxButton
                          class="absolute inset-y-0 right-0 flex items-center pr-2"
                        >
                          <SelectorIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </ComboboxButton>
                      </div>
                      <TransitionRoot
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        @after-leave="query = ''"
                      >
                        <ComboboxOptions
                          class="z-10 absolute max-h-28 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                          <ComboboxOption
                            v-for="zip in zipcode.data.data"
                            as="template"
                            :key="zip.id"
                            :value="zip"
                            v-slot="{ selected, active }"
                          >
                            <li
                              class="relative cursor-default select-none py-2 pl-10 pr-4"
                              :class="{
                                'bg-teal-600 text-theme-secondary': active,
                                'text-theme-body': !active,
                              }"
                            >
                              <span
                                class="block truncate"
                                :class="{
                                  'font-medium': selected,
                                  'font-normal': !selected,
                                }"
                              >
                                {{ zip.zip_code }}
                              </span>
                              <span
                                v-if="selected"
                                class="absolute inset-y-0 left-0 flex items-center pl-3"
                                :class="{
                                  'text-brand-indigo': active,
                                  'text-teal-600': !active,
                                }"
                              >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ComboboxOption>
                        </ComboboxOptions>
                      </TransitionRoot>
                    </div>
                  </Combobox>
                  <span
                    v-if="
                      isSubmit && v$.address.zip_code.$silentErrors.length > 0
                    "
                    class="flex text-left text-sm font-light text-red-400 pt-2"
                  >
                    <span
                      v-if="
                        v$.address.zip_code.$silentErrors[0].$validator ==
                          'required'
                      "
                      >Zip code is required.</span
                    >
                  </span>
                </div>
              </div>
              <div class="col-span-2" :class="visibility ? 'visible' : 'invisible'">
                <label for="ltl" class="ltl-checkbox">
                  <Input
                    id="ltl"
                    aria-describedby="ltl"
                    v-model:modelValue="address.ltl"
                    name="ltl"
                    type="checkbox"
                  />
                  <span>Can you accept LTL (Less Than Truckload) shipments?</span>
                </label>
              </div>
            </template>
            <template v-else>
              <div class="col-span-2 input">
                <Input
                  padding="4px"
                  label="Address Line 1"
                  v-model:modelValue="address.address1"
                  placeholder="Address Line 1"
                />
                <span
                  v-if="
                    isSubmit && v$.address.address1.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400 pt-2"
                >
                  <span
                    v-if="
                      v$.address.address1.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Address is required.</span
                  >
                </span>
              </div>
              <div class="col-span-2 input">
                <Input
                  label="Address Line 2"
                  v-model:modelValue="address.address2"
                  placeholder="Address Line 2"
                />
              </div>
              <div class="col-span-2 grid grid-cols-3 gap-8">
                <div class="input">
                  <Input
                    label="City"
                    v-model:modelValue="address.city"
                    placeholder="City"
                  />
                  <span
                    v-if="isSubmit && v$.address.city.$silentErrors.length > 0"
                    class="flex text-left text-sm font-light text-red-400 pt-2"
                  >
                    <span
                      v-if="
                        v$.address.city.$silentErrors[0].$validator ==
                          'required'
                      "
                      >City is required.</span
                    >
                  </span>
                </div>

                <div>
                  <Listbox
                    as="div"
                    v-model="address.state"
                    class="store-switcher"
                  >
                    <div>
                      <ListboxLabel>State</ListboxLabel>
                      <ListboxButton class="group ss-btn">
                        <span class="flex w-full justify-between items-center">
                          <span
                            class="flex min-w-0 items-center justify-between space-x-3"
                          >
                            <div
                              class="flex items-center justify-center rounded-full w-8 h-8 text-brand-indigo bg-purple-100 flex-shrink-0 order-bg"
                            >
                              <Icon name="shop" class="w-5 h-5" />
                            </div>
                            <span
                              class="flex-1 flex flex-col min-w-0"
                              v-if="address.state"
                            >
                              <span
                                class="text-brand-secondary text-sm font-medium truncate"
                                >{{
                                  address.state
                                    ? address.state.state_name
                                    : null
                                }}</span
                              >
                            </span>
                          </span>
                          <SelectorIcon
                            class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <ListboxOptions
                        class="item-opts"
                        style="height:110px; overflow:auto;"
                      >
                        <ListboxOption
                          as="div"
                          v-for="opt in st.data"
                          :key="opt.id"
                          :value="opt"
                          @click="selectZipcode(opt.state)"
                          class="group item-opt"
                        >
                          <span
                            class="flex min-w-0 items-center justify-between space-x-3"
                          >
                            <span class="flex-1 flex flex-col min-w-0">
                              <span class="name"
                                >{{ opt.state_name
                                }}{{ " (" + opt.state + ")" }}</span
                              >
                            </span>
                          </span>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </Listbox>
                  <span
                    v-if="isSubmit && v$.address.state.$silentErrors.length > 0"
                    class="flex text-left text-sm font-light text-red-400 pt-2"
                  >
                    <span
                      v-if="
                        v$.address.state.$silentErrors[0].$validator ==
                          'required'
                      "
                      >State is required.</span
                    >
                  </span>
                </div>

                <div>
                  <Combobox
                    :disabled="!address.state"
                    v-model="address.zip_code"
                    :value="address.zip_code ? address.zip_code : ''"
                    class="store-switcher"
                    nullable
                  >
                    <div class="relative mt-1">
                      <ComboboxLabel>Zip Code</ComboboxLabel>
                      <div
                        class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                      >
                        <ComboboxInput
                          :disabled="!address.state"
                          class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                          :displayValue="(zip) => zip?.zip_code"
                          @change="getZipcodes(state_code, $event.target.value)"
                        />
                        <ComboboxButton
                          class="absolute inset-y-0 right-0 flex items-center pr-2"
                        >
                          <SelectorIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </ComboboxButton>
                      </div>
                      <TransitionRoot
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        @after-leave="query = ''"
                      >
                        <ComboboxOptions
                          class="z-10 absolute max-h-28 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                          <!-- <div
                        v-if="zipcode.data.data.length === 0 && query !== ''"
                        class="relative cursor-default select-none py-2 px-4 text-gray-700"
                      >
                        Nothing found.
                      </div> -->

                          <ComboboxOption
                            v-for="zip in zipcode.data.data"
                            as="template"
                            :key="zip.id"
                            :value="zip"
                            v-slot="{ selected, active }"
                          >
                            <li
                              class="relative cursor-default select-none py-2 pl-10 pr-4"
                              :class="{
                                'bg-teal-600 text-theme-secondary': active,
                                'text-theme-body': !active,
                              }"
                            >
                              <span
                                class="block truncate"
                                :class="{
                                  'font-medium': selected,
                                  'font-normal': !selected,
                                }"
                              >
                                {{ zip.zip_code }}
                              </span>
                              <span
                                v-if="selected"
                                class="absolute inset-y-0 left-0 flex items-center pl-3"
                                :class="{
                                  'text-brand-indigo': active,
                                  'text-teal-600': !active,
                                }"
                              >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ComboboxOption>
                        </ComboboxOptions>
                      </TransitionRoot>
                    </div>
                  </Combobox>
                  <span
                    v-if="
                      isSubmit && v$.address.zip_code.$silentErrors.length > 0
                    "
                    class="flex text-left text-sm font-light text-red-400 pt-2"
                  >
                    <span
                      v-if="
                        v$.address.zip_code.$silentErrors[0].$validator ==
                          'required'
                      "
                      >Zip code is required.</span
                    >
                  </span>
                </div>
              </div>
              <div class="col-span-2">
                <label for="ltl" class="ltl-checkbox">
                  <Input
                    id="ltl"
                    aria-describedby="ltl"
                    v-model:modelValue="address.ltl"
                    name="ltl"
                    type="checkbox"
                  />
                  <span>Can you accept LTL (Less Than Truckload) shipments?</span>
                </label>
              </div>
            </template>
            <div class="col-span-2 flex justify-end">
              <div class="w-full flex-shrink-0" @click="save">
                <Button title="Continue" :disabled="isSubmit" />
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-8" v-show="currentStep === 2">
            <div class="flex items-center justify-between">
              <p class="text-base font-medium text-brand-body">
                Submit required documents for
                {{
                  address.data?.data?.address?.is_wholesaler == 0
                    ? "Retailer"
                    : "Wholesaler"
                }}
                in {{ address.data?.data?.address?.state }}
              </p>
              <span
                class="flex items-center uppercase font-normal text-xs cursor-pointer text-brand-body"
                @click="currentStep = 1"
                ><ChevronLeftIcon class="w-4 h-4 ml-1" /> Go Back</span
              >
            </div>
            <div class="flex flex-col space-y-7 text-left">
              <StaticCheckList
                v-for="(lic, i) in address.licenses"
                :key="lic.key"
                :title="lic.name"
              >
                <input
                  @click="handle($event)"
                  @change="handleFileUpload($event, lic.key, i)"
                  type="file"
                  :id="`doc-${i}-file`"
                  class="absolute w-0 h-0 opacity-0"
                />
                <template v-if="files[lic.key] != null">
                  <span
                    @click="showFile(lic.key)"
                    class="flex items-center hover:text-brand-secondary text-brand-body border-b border-dashed border-brand-body text-sm leading-none font-light pb-0.5 cursor-pointer"
                    >{{ lic.document ? lic.document.file_url : null }}</span
                  >
                  <span
                    @click="removeFile(lic.key)"
                    class="flex items-center justify-center cursor-pointer"
                    ><Icon name="delete" class="w-5 h-5"
                  /></span>
                </template>
                <template v-else-if="lic.opt_out">
                  <label
                    @click="openOptOut()"
                    class="bg-gray-200 text-theme-secondary text-xs font-normal px-5 py-2 rounded leading-none cursor-pointer border border-transparent hover:border-gray-300"
                    >Fill out form</label
                  >
                </template>
                <template v-else>
                  <span
                    class="flex items-center hover:text-brand-secondary text-brand-body border-b border-dashed border-brand-body text-sm leading-none font-light pb-0.5 cursor-pointer"
                    @click="
                      showFile(
                        lic.key,
                        lic.sample ? lic.sample : null
                      )
                    "
                    >Sample</span
                  >
                  <label
                    :for="`doc-${i}-file`"
                    class="bg-gray-200 text-theme-secondary text-xs font-normal px-5 py-2 rounded leading-none cursor-pointer border border-transparent hover:border-gray-300"
                    >Upload License</label
                  >
                </template>
              </StaticCheckList>
            </div>
            <div class="grid grid-cols-2 gap-5 pt-5">
              <div @click="willdoLater">
                <Button title="I'll do later" class="no-bg" />
              </div>
              <div @click="submitFile">
                <Button title="Continue" />
              </div>
            </div>
          </div>
          <div
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            v-show="currentStep > 2"
          >
            <div
              class="flex items-center justify-center w-20 h-20 bg-green-300 text-white rounded-full"
            >
              <CheckIcon class="w-16 h-16" />
            </div>
            <p class="text-base text-black font-semibold">
              Successfully Submitted
            </p>
            <p class="text-base text-black font-medium">
              {{ address.data?.data?.address?.address1 }}
              {{ address.data?.data?.address?.address2 }} <br />
              {{ address.data?.data?.address?.city }},
              {{ address.data?.data?.address?.state }}
              {{ address.data?.data?.address?.zip_code }}
            </p>
            <span class="text-md text-brand-body"
              >We are currently reviewing your documents.</span
            >
            <span class="text-sm text-brand-body"
              ><Icon name="reload" class="float-left" /> Processed within
              24h</span
            >
          </div>
          <!-- if no files are added then show this -->
          <div
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            v-show="currentStep === 1.2"
          >
            <div
              class="flex items-center justify-center w-20 h-20 bg-green-300 text-white rounded-full"
            >
              <CheckIcon class="w-16 h-16" />
            </div>
            <p class="text-base text-black font-semibold">
              Successfully Submitted
            </p>
            <p class="text-base text-black font-medium">
              {{ address.data?.data?.address?.address1 }}
              {{ address.data?.data?.address?.address2 }} <br />
              {{ address.data?.data?.address?.city }},
              {{ address.data?.data?.address?.state }}
              {{ address.data?.data?.address?.zip_code }}
            </p>
            <span class="text-md text-brand-body"
              >Your new address is ready to use.</span
            >
          </div>
          <!-- <div
            class="flex flex-col items-center justify-center py-4 text-base text-brand-gray"
            v-show="currentStep === 1.2 || currentStep > 2"
          >
            <div class="flex items-center space-x-2 ">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 flex-shrink-0 -mt-0.5"
              >
                <circle cx="16.5" cy="16.5" r="16.5" fill="#FFD597" />
                <path
                  d="M17.1998 7.00449C16.9259 6.48674 16.0741 6.48674 15.8002 7.00449L8.67515 20.4628C8.61102 20.5835 8.57922 20.7186 8.58286 20.8552C8.5865 20.9918 8.62546 21.1251 8.69594 21.2421C8.76641 21.3591 8.86601 21.4559 8.98501 21.523C9.10401 21.5901 9.23837 21.6253 9.37499 21.625H23.625C23.7615 21.6253 23.8958 21.5902 24.0147 21.5231C24.1336 21.4561 24.2331 21.3593 24.3035 21.2424C24.3739 21.1254 24.4128 20.9922 24.4164 20.8557C24.42 20.7192 24.3882 20.5841 24.324 20.4636L17.1998 7.00449ZM17.2917 19.25H15.7083V17.6667H17.2917V19.25ZM15.7083 16.0833V12.125H17.2917L17.2924 16.0833H15.7083Z"
                  fill="#BD966F"
                />
              </svg>
              <span class="font-light"
                >Did you know you can handle taxes yourself?</span
              >
            </div>
            <p class="font-light mt-2">
              <span
                class="font-medium text-brand-secondary border-b-2 border-brand-primary border-dotted cursor-pointer"
                @click="showTaxStep = true"
                >Click here</span
              >
              to opt-out of Medusa collecting taxes
            </p>
          </div> -->
        </div>
      </Block>
      <Confirm
        :isVisible="showConfirmDialouge"
        cancelButton="Go Back"
        confirmButton="Yes"
        @cancel="showConfirmDialouge = false"
        @confirm="storeAddress"
      >
        <p>
          The address you entered does not appear to be a commercial address.
        </p>
        <p>
          Are you sure this is the correct address?
          <br />{{ address.address1 }} {{ address.address2 }}<br />
          {{ address.city }},
          {{
            typeof address.state == "string"
              ? address.state
              : address.state.state
          }}
          {{
            typeof address.zip_code == "string"
              ? address.zip_code
              : address.zip_code.zip_code
          }}
        </p>
      </Confirm>
      <!-- File Preview Modal  -->
      <PreviewFileModal 
        :previewFileModal="previewFileModal" 
        :selectedUrl="filearr"
        :src="filepreview[selectedLicense]"
        @close="previewFileModal = false"
      />
      <!-- File Preview Modal Ends -->
    </ModalFull>

    <!-- Edit Address Modal -->
    <ModalFull
      :is-visible="updateAddressModal"
      @close="updateAddressModal = updatesubmit = false"
    >
      <Block
        icon="shop"
        title="Location Details"
        :streachActions="true"
        v-show="!showOptOutForm && !showTaxDownloadOptout"
      >
        <template #action>
          <div
            class="flex-grow flex items-streach justify-between h-12 -my-4 pl-5"
          >
            <div class="steps flex items-stretch space-x-4"></div>
            <button
              class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
              @click="updateAddressModal = updatesubmit = false"
            >
              <XIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
        <div>
          <div class="flex flex-col space-y-8">
            <div>
              <!-- <div class="flex items-center justify-end mb-4">
                <span
                  class="flex items-center uppercase font-normal text-xs cursor-pointer text-brand-body"
                  @click="showDeleteDialouge = true"
                  >Delete<Icon name="delete" class="ml-1 -mt-1 w-4 h-4"
                /></span>
              </div> -->

              <div class="grid grid-cols-2 gap-4 ">
                <div class="col-span-2 input">
                  <Input
                    v-model:modelValue="nickname_edit"
                    placeholder="Location Name"
                    maxlength="32"
                    @keyup="checkEditValue()"
                  >
                    <div class="flex items-center w-full space-x-2">
                      <label for="">Location Name</label>
                      <tippy interactive>
                        <span class="cursor-pointer">
                          <Icon
                            name="info"
                            class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                          />
                        </span>
                        <template #content>
                          <p class="text-left px-1.5 text-xs">
                            A location name will change the name of your
                            location on the shipping label. This is helpful when
                            you have a company name but stores with different
                            names.
                            <br />
                            By default, if you don't enter a location name,
                            <b>{{ company_name }}</b> will be the location on
                            the shipping label.
                          </p>
                        </template>
                      </tippy>
                    </div>
                  </Input>
                </div>
                <div class="col-span-2 input">
                  <Input
                    label="Address Line 1"
                    :modelValue="
                      editadd.data.address
                        ? editadd.data.address.address1
                        : null
                    "
                    placeholder="Address Line "
                    disabled
                  />
                </div>
                <div
                  class="col-span-2 input"
                  v-if="
                    editadd.data.address
                      ? editadd.data.address.address2
                        ? true
                        : false
                      : false
                  "
                >
                  <Input
                    label="Address Line 2"
                    :modelValue="
                      editadd.data.address
                        ? editadd.data.address.address2
                        : null
                    "
                    placeholder="Address Line 2"
                    disabled
                  />
                </div>

                <div class="col-span-2 grid grid-cols-3 gap-8">
                  <div class="input">
                    <Input
                      label="City"
                      placeholder="City"
                      :modelValue="
                        editadd.data.address ? editadd.data.address.city : null
                      "
                      disabled
                    />
                    <!-- v-model:modelValue="address.city" -->
                  </div>

                  <div class="input">
                    <Input
                      label="State"
                      placeholder="State"
                      :modelValue="
                        editadd.data.address ? editadd.data.address.state : null
                      "
                      disabled
                    />
                    <!-- v-model:modelValue="address.state" -->
                  </div>

                  <div class="input">
                    <Input
                      label="Zip Code"
                      placeholder="Zipcode"
                      :modelValue="
                        editadd.data.address
                          ? editadd.data.address.zip_code
                          : null
                      "
                      disabled
                    />
                    <!-- v-model:modelValue="address.zip_code" -->
                  </div>
                </div>
                <!-- <div class="col-span-2 flex justify-end">
                  <div class="w-full flex-shrink-0" @click="saveNickname()">
                    <Button title="Update Address" />
                  </div>
                </div> -->
              </div>
              <!-- <p
                class="mt-1.5 w-full text-left text-md font-light text-brand-body"
              >
                {{
                  editadd.data.address ? editadd.data.address.address1 : null
                }},<br />
                {{ editadd.data.address ? editadd.data.address.city : null }}
                {{ editadd.data.address ? editadd.data.address.state : null }}
                {{
                  editadd.data.address ? editadd.data.address.zip_code : null
                }}
              </p> -->
            </div>

            <div
              class="flex items-center justify-between"
              v-show="editadd.data.length > 0"
            >
              <h3 class="text-base font-medium text-theme-secondary">
                Your documents
              </h3>
            </div>
            <div class="flex flex-col space-y-7">
              <StaticCheckList
                v-for="(lic, i) in editadd.data.license"
                :key="lic.key"
                :checkType="
                  lic
                    ? lic.document
                      ? lic.document.is_approved == 0
                        ? 'warning'
                        : filedocument[lic.key] != null || lic.document
                        ? 'success'
                        : ''
                      : ''
                    : ''
                "
                :title="lic.name"
              >
                <input
                  @click="handle($event)"
                  @change="FileUpload($event, lic.key, i)"
                  type="file"
                  :id="`doc-${i}-file`"
                  class="absolute w-0 h-0 opacity-0"
                />
                <template v-if="lic.document || filedocument[lic.key] != null">
                  <span
                    @click="
                      showEditFile(
                        lic.key,
                        lic.document ? lic.document.file_url : null,
                        lic.document ? lic.document.isFake : false
                      )
                    "
                    class="flex items-center hover:text-brand-secondary text-brand-body border-b border-dashed border-brand-body text-sm leading-none font-light pb-0.5 cursor-pointer"
                    >{{ lic.document ? 'view license' : null }}</span
                  >
                  <span
                    v-if="!lic.opt_out"
                    class="flex items-center justify-center cursor-pointer"
                    @click="removeFiledocument(lic.key, i)"
                    ><Icon name="delete" class="w-5 h-5"
                  /></span>
                  <span v-else />
                </template>
                <template v-else-if="lic.opt_out">
                  <label
                    @click="openOptOutForm()"
                    class="bg-gray-200 text-theme-secondary text-xs font-normal px-5 py-2 rounded leading-none cursor-pointer border border-transparent hover:border-gray-300"
                    >Fill out form</label
                  >
                </template>
                <template v-else>
                  <span
                    class="flex items-center hover:text-brand-secondary text-brand-body border-b border-dashed border-brand-body text-sm leading-none font-light pb-0.5 cursor-pointer"
                    @click="
                      showEditFile(
                        lic.key,
                        lic.sample ? lic.sample : null
                      )
                    "
                    >Sample</span
                  >
                  <label
                    :for="`doc-${i}-file`"
                    class="bg-gray-200 text-theme-secondary text-xs font-normal px-5 py-2 rounded leading-none cursor-pointer border border-transparent hover:border-gray-300"
                    >Upload License</label
                  >
                </template>
                <template #msg>
                  <p
                    class="text-center text-xs font-light text-brand-body pt-2 -mb-5"
                    v-if="
                      lic
                        ? lic.document
                          ? lic.document.is_approved == 0
                          : null
                        : null
                    "
                  >
                    Reviewing or Invalid
                  </p>
                  <p
                    class="text-center text-xs font-light text-brand-body pt-2 -mb-5"
                    v-else-if="
                      lic.document ? lic.document.expiration_date != null : null
                    "
                  >
                    Expires
                    {{ lic.document ? lic.document.expiration_date : null }}
                  </p>
                  <p
                    class="flex text-center text-xs font-light text-red-400 pt-2 -mb-5"
                    v-if="
                      updatesubmit &&
                        lic.required == true &&
                        filedocument[lic.key] == null
                    "
                  >
                    This License field is required.
                  </p>
                </template>
              </StaticCheckList>
            </div>
            <!-- editadd.data.address ? true : false -->
            <div class="col-span-2 flex justify-end pt-3">
              <div class="w-full flex-shrink-0" @click="updateFile">
                <Button title="Update" />
              </div>
            </div>
          </div>
          <div
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            v-show="currentStep === 2"
          >
            <div
              class="flex items-center justify-center w-20 h-20 bg-green-300 text-white rounded-full"
            >
              <CheckIcon class="w-16 h-16" />
            </div>
            <p class="text-base text-black font-medium">
              Successfully Deleted
            </p>
            <span class="text-md text-brand-body"
              >Your address is successfully deleted.</span
            >
          </div>
        </div>
      </Block>

      <!-- <Confirm
        :isVisible="showDeleteDialouge"
        cancelButton="Go Back"
        confirmButton="Yes"
        @cancel="showDeleteDialouge = false"
        @confirm="delAddr"
      >
        <p>
          Are you sure you want to delete this address?
        </p>
      </Confirm> -->
    </ModalFull>

    <!-- Updated From with Scrollable area -->
    <ModalFull
      :is-visible="showOptOutForm"
      @close="showOptOutForm = false"
      :reset-container="true"
    >
      <Block
        icon="shop"
        title="Tax Opt-out Authorization - Excise Tax Opt-out Form"
        :streachActions="true"
        class="modal wd-64"
      >
        <div class="form-scroll">
          <div class="grid grid-cols-2 gap-6">
            <div class="col-span-2 flex flex-col gap-3">
              <p class="text-left w-full text-theme-dark font-light text-sm">
                Medusa Distribution LLC customers in certain states that allow
                for the retailer or distributor in that state to remit excise
                taxes directly may opt out of having Medusa Distribution LLC
                collect and remit that tax.
              </p>

              <p class="text-left w-full text-theme-dark font-light text-sm">
                As a licensed taxpayer in your state, you may elect to opt out
                from Medusa Distribution LLC collecting excise tax and remitting
                it to your state tax authority on your behalf.
              </p>

              <p class="text-left w-full text-theme-dark font-light text-sm">
                <b class="font-normal"
                  >If you elect to opt out, you are required to complete this
                  form.</b
                >
                Completing and signing this form will indicate that you have
                elected to not have your orders taxed by Medusa Distribution LLC
                and will collect any tax due and remit it to the state into
                which your orders are being shipped.
              </p>
            </div>
            <div class="col-span-2 grid grid-cols-2 gap-6">
              <div class="input">
                <Input
                  placeholder="Location Name"
                  maxlength="32"
                  inputClass="sm"
                  v-model:modelValue="optout.company"
                >
                  <div class="flex items-center w-full space-x-2">
                    <label
                      for=""
                      class="text-sm font-normal relative leading-none text-brand-secondary mb-1"
                      >Company Legal Name</label
                    >
                    <tippy interactive>
                      <span class="cursor-pointer">
                        <Icon
                          name="info"
                          class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                        />
                      </span>
                      <template #content>
                        <p class="text-left px-1.5 text-xs">
                          Info about company
                        </p>
                      </template>
                    </tippy>
                  </div>
                </Input>
                <span
                  v-if="
                    isOptoutSubmit && v$.optout.company.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400"
                >
                  <span
                    v-if="
                      v$.optout.company.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Company name is required.</span
                  >
                </span>
              </div>
              <div class="input">
                <Input
                  placeholder="Enter Company Federal EIN Number"
                  inputClass="sm"
                  maxlength="32"
                  v-model:modelValue="optout.fein"
                >
                  <div class="flex items-center w-full space-x-2">
                    <label
                      for=""
                      class="text-sm font-normal relative leading-none text-brand-secondary mb-1"
                      >Company Federal EIN Number</label
                    >
                    <tippy interactive>
                      <span class="cursor-pointer">
                        <Icon
                          name="info"
                          class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                        />
                      </span>
                      <template #content>
                        <p class="text-left px-1.5 text-xs">
                          Info about Company Federal EIN Number
                        </p>
                      </template>
                    </tippy>
                  </div>
                </Input>
                <span
                  v-if="
                    isOptoutSubmit && v$.optout.fein.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400"
                >
                  <span
                    v-if="
                      v$.optout.fein.$silentErrors[0].$validator == 'required'
                    "
                    >FEIN is required.</span
                  >
                </span>
              </div>
            </div>
            <div class="col-span-2 grid grid-cols-2 gap-6">
              <div class="input">
                <Input
                  label="Company Address (Street):"
                  placeholder="Enter street address"
                  v-model:modelValue="optout.company_address"
                  inputClass="sm"
                />
                <span
                  v-if="
                    isOptoutSubmit &&
                      v$.optout.company_address.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400"
                >
                  <span
                    v-if="
                      v$.optout.company_address.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Address is required.</span
                  >
                </span>
              </div>
              <div class="input">
                <Input
                  label="Company Address (City, State, Zip code):"
                  placeholder="Enter City, State, Zip code"
                  v-model:modelValue="optout.company_city_state_zip"
                  inputClass="sm"
                />
                <span
                  v-if="
                    isOptoutSubmit &&
                      v$.optout.company_city_state_zip.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400"
                >
                  <span
                    v-if="
                      v$.optout.company_city_state_zip.$silentErrors[0]
                        .$validator == 'required'
                    "
                    >Address is required.</span
                  >
                </span>
              </div>
            </div>
            <div class="col-span-2 grid grid-cols-3 gap-6">
              <div class="input">
                <Input
                  label="State tax license number"
                  placeholder="Enter State tax license number"
                  v-model:modelValue="optout.state_tax_license_number"
                  inputClass="sm"
                />
                <span
                  v-if="
                    isOptoutSubmit &&
                      v$.optout.state_tax_license_number.$silentErrors.length >
                        0
                  "
                  class="flex text-left text-sm font-light text-red-400"
                >
                  <span
                    v-if="
                      v$.optout.state_tax_license_number.$silentErrors[0]
                        .$validator == 'required'
                    "
                    >Locense number is required.</span
                  >
                </span>
              </div>
              <div class="input">
                <Input
                  label="Taxpayer's name(s)"
                  placeholder="Enter Taxpayer's name(s)"
                  v-model:modelValue="optout.taxpayer_name"
                  inputClass="sm"
                />
                <span
                  v-if="
                    isOptoutSubmit &&
                      v$.optout.taxpayer_name.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400"
                >
                  <span
                    v-if="
                      v$.optout.taxpayer_name.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Taxpayer name is required.</span
                  >
                </span>
              </div>
              <div class="input">
                <Input
                  label="Role at Company"
                  placeholder="Enter Role at Company (Owner, Manager, etcx.)"
                  v-model:modelValue="optout.role_at_company"
                  inputClass="sm"
                />
                <span
                  v-if="
                    isOptoutSubmit &&
                      v$.optout.role_at_company.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400"
                >
                  <span
                    v-if="
                      v$.optout.role_at_company.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Role is required.</span
                  >
                </span>
              </div>
            </div>
            <div class="col-span-2 text-left space-y-2">
              <p
                class="text-sm font-normal relative leading-none text-brand-secondary pb-1"
              >
                The Tax payer agreeing to the following:
              </p>
              <div>
                <label class="confirm-checkbox">
                  <input
                    id="confirm-checkbox"
                    aria-describedby="confirm-checkbox"
                    v-model="optout.acknowledge1"
                    name="confirm-checkbox"
                    type="checkbox"
                  />
                  <span
                    >I confirm the accuracy of the above information and
                    acknowledge the potential legal consequences if it is found
                    to be false.</span
                  >
                </label>
              </div>
              <div>
                <label class="confirm-checkbox">
                  <input
                    id="confirm-checkbox"
                    aria-describedby="confirm-checkbox"
                    v-model="optout.acknowledge2"
                    name="confirm-checkbox"
                    type="checkbox"
                  />
                  <span
                    >I have voluntarily chosen not to have Medusa Distribution
                    LLC collect and remit taxes on my orders to state into which
                    my orders are being shipped. I will remit the taxes due
                    directly to the state as per the state's tax policies.</span
                  >
                </label>
              </div>
            </div>
            <div class="col-span-2">
              <div
                class="input flex items-start justify-start flex-col relative group"
              >
                <label
                  for=""
                  class="text-sm font-normal relative leading-none text-brand-secondary mb-1"
                  >Signature</label
                >
                <VueSignaturePad
                  id="signature"
                  height="100px"
                  ref="signaturePad"
                  class="rounded border-2 border-brand-ash border-opacity-60 transition group-hover:border-theme-primary hover:border-theme-primary focus:outline-none focus:ring-transparent placeholder-gray-300"
                  :options="{
                    onBegin: () => {
                      $refs.signaturePad.resizeCanvas();
                    },
                  }"
                />
                <button
                  class="flex-shrink-0 inline-flex text-xs items-center space-x-1 bg-transparent text-theme-secondary border border-gray-200 bg-gray-200 hover:bg-theme-secondary hover:text-white px-3 py-2 rounded leading-none transition-colors duration-300 absolute right-2 bottom-2"
                  @click="undo"
                >
                  Undo Signature
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-5 pt-8">
          <div>
            <Button
              title="I'll do later"
              class="no-bg"
              @click="showOptOutForm = false"
            />
          </div>
          <div>
            <Button
              title="Continue"
              @mouseup="
                (showTaxDownload = true), (showTaxStep = false), taxOptOutForm()
              "
            />
          </div>
        </div>
      </Block>
    </ModalFull>
    <ModalFull
      :is-visible="showTaxDownloadOptout"
      @close="showTaxDownloadOptout = false"
      :reset-container="true"
    >
      <!-- Tax Form Block -->
      <Block
        v-show="showTaxDownloadOptout"
        icon="shop"
        title="Tax Opt-out Authorization - Excise Tax Opt-out Form"
        :streachActions="true"
        class="modal wd-50"
      >
        <div class="grid grid-cols-2 gap-5 pt-8">
          <Button
            @click="
              showTaxDownloadOptout = false, 
              showAddressModal = false,
              showOptOutForm = false,
              updateAddressModal = false,
              showTaxStep = false,
              showTaxDownload = false,
              currentStep = 1
            "
            title="Close"
            class="no-bg"
          />
          <div>
            <Button @click="downloadOptoutForm" title="Download Signed Form" />
          </div>
        </div>
      </Block>
    </ModalFull>
     
    <!-- Preview File Modal -->
    <PreviewFileModal 
      :previewFileModal="previewEditFileModal" 
      :selectedUrl="filearr"
      :src="filepreview[selectedLicense]"
      @close="previewEditFileModal = false"
    />
    <Confirm
      :isVisible="showDeleteDialouge"
      cancelButton="Go Back"
      confirmButton="Yes"
      :isConfirm="addresses.data.length > 1 ? true : false"
      @cancel="showDeleteDialouge = false"
      @confirm="delAddr"
    >
      <p
        v-text="
          addresses.data.length > 1
            ? 'Are you sure you want to delete this address?'
            : 'Please add a new shipping address before deleting this address. No accounts can have 0 shipping addresses'
        "
      ></p>
    </Confirm>
  </div>
</template>

<script>
import {
  DotsVerticalIcon,
  XIcon,
  ChevronLeftIcon,
  CheckIcon,
} from "@heroicons/vue/solid";
import { SelectorIcon, LocationMarkerIcon } from "@heroicons/vue/outline";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxLabel,
  Combobox,
  ComboboxInput,
  ComboboxLabel,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ref, inject, watch, reactive } from "vue";
import Block from "../../common/Block.vue";
import Account from "@/modules/Account";
import { required, minLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import PreviewFileModal from "./PreviewFileModal";
import {
  queryAutocompleteForSuggestions,
  selectSuggestion,
  formatSuggestion,
} from "@/modules/AutocompleteAddress";
// const stores = [
//   {
//     shipping: 'Shipping'
//   },
//   {
//     shipping: 'Billing'
//   },
//   {
//     shipping: 'Main'
//   },
// ];

export default {
  name: "Stores",
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ListboxLabel,
    Combobox,
    ComboboxInput,
    ComboboxLabel,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    SelectorIcon,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Block,
    XIcon,
    ChevronLeftIcon,
    CheckIcon,
    LocationMarkerIcon,
    PreviewFileModal
  },
  data() {
    return {
      v$: useVuelidate(),
      isSubmit: false,
      isOptoutSubmit: false,
      submit: false,
      // updatesubmit: false,
      showConfirmDialouge: false,
      showDeleteDialouge: false,
      totalVuePackages: null,
      files: [],
      filedocument: [],
      selectedUrl: "",
      filearr: "",
      state_code: "",
      tradegeckoId: null,
      filepreview: [],
      selectedLicense: "",
      isDisabled: true,
      nickname_edit: "",
      company_name: localStorage.getItem("company_name"),
      visibility: false,
      selectedState: "",
      optout: {},
      optoutFormURL: "",
    };
  },
  validations() {
    return {
      address: {
        address1: { required },
        city: { required },
        state: { required },
        // label: {required},
        zip_code: { required },
      },
      optout: {
        company: { required },
        fein: { required },
        company_address: { required },
        company_city_state_zip: { required },
        taxpayer_name: { required },
        role_at_company: { required },
        state_tax_license_number: { required },
        acknowledge1: { required },
        acknowledge2: { required },
      },
    };
  },
  methods: {
    queryAutocompleteForSuggestions,
    selectSuggestion,
    formatSuggestion,
    willdoLater() {
      this.currentStep = 1.2;
      this.reset();
      // this.showAddressModal = false;
      this.getAddress();
    },

    checkValue() {
      this.address["nickname"] = this.address["nickname"].replace(
        /[^a-zA-Z0-9 ]/g,
        ""
      );
    },
    checkEditValue() {
      this.nickname_edit = this.nickname_edit.replace(/[^a-zA-Z0-9 ]/g, "");
    },
    saveNickname() {
      console.log(this.editadd.data.address);
    },
    delAddr() {
      this.deleteAddress(this.tradegeckoId);
      this.showDeleteDialouge = false;
      // this.updateAddressModal = false;
      this.currentStep = 2;
      // this.currentStep = 2;
    },

    delAddress(trade_gecko_id) {
      this.tradegeckoId = trade_gecko_id;
      // this.deleteAddress(this.tradegeckoId);
      this.showDeleteDialouge = true;
      // this.updateAddressModal = false;
      // this.currentStep = 2;
      // this.currentStep = 2;
    },
    reset() {
      this.address.address1 = "";
      this.address.address2 = "";
      this.address.city = "";
      this.address.state = "";
      this.address.nickname = "";
      this.address.label = "shipping";
      this.optout.company = "";
      this.optout.fein = "";
      this.optout.company_address = "";
      this.optout.company_city_state_zip = "";
      this.optout.taxpayer_name = "";
      this.optout.role_at_company = "";
      this.optout.state_tax_license_number = "";
      this.files = [];
    },
    show() {
      this.showAddressModal = true;
      if (
        this.currentStep == 2 ||
        this.currentStep == 3 ||
        this.currentStep == 1.2
      ) {
        this.address = {
          address1: "",
          city: "",
          nickname: "",
          state: "",
          label: "",
          ltl: false,
        };
        this.currentStep = 1;
      }
    },
    handle(e) {
      e.target.value = "";
    },
    handleFileUpload(e, key, i) {
      this.files[key] = e.target.files[0];
      this.filepreview[key] = URL.createObjectURL(e.target.files[0]);
      this.address.licenses[i].document = {
        file_url: e.target.files[0].name,
      };
    },
    FileUpload(e, key, i) {
      this.filedocument[key] = e.target.files[0];
      this.filepreview[key] = URL.createObjectURL(e.target.files[0]);
      this.editadd.data.license[i].document = {
        file_url: e.target.files[0].name,
        isFake: true,
      };
    },
    removeFiledocument(key, k) {
      this.filedocument[key] = null;
      this.editadd.data.license[k].document = "";
    },
    removeFile(key) {
      this.files[key] = null;
    },
    showFile(key, url) {
      this.previewFileModal = true;
      this.selectedLicense = key;
      if (url) {
        this.selectedUrl = url;
        this.filearr = process.env.LICENSE_FILE_URL + this.selectedUrl;
        return this.filearr;
      }
    },
    showEditFile(key, url, isFake = false) {
      this.previewEditFileModal = true;
      if (url && !isFake) {
        this.selectedUrl = url;
        this.filearr = process.env.LICENSE_FILE_URL + this.selectedUrl;
        return this.filearr;
      } else {
        this.selectedLicense = key;
      }
    },
    updateFile() {
      this.updatesubmit = true;
      const f = [this.filedocument];
      let formData = new FormData();
      for (const key in f[0]) {
        formData.append(`license[${key}]`, f[0][key]);
      }
      formData.append("address_id", this.editadd.addId);
      formData.append("nickname", this.nickname_edit);
      this.axios.authApi
        .post("/account/add-license", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        // .then(res => console.log("response",res));
        .then((resp) => {
          if (resp.data.status) {
            //  console.log("response", resp);
            this.updateAddressModal = false;
            this.getAddress();
            this.updatesubmit = false;
          } else {
            // address.error = resp.data.error;
            console.log("response", resp);
          }
        });
    },
    submitFile() {
      this.submit = true;
      const f = [this.files];
      let formData = new FormData();
      for (const key in f[0]) {
        formData.append(`license[${key}]`, f[0][key]);
      }
      formData.append("address_id", this.address.address_id);
      this.axios.authApi
        .post("/account/add-license", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        // .then(res => console.log("response",res));
        .then((resp) => {
          if (resp.data.status) {
            //  console.log("response", resp);
            this.currentStep = 3;
            this.reset();
            // this.getAddress();
          } else {
            // address.error = resp.data.error;
            console.log("response", resp);
          }
        });
    },
    edit(ids, nickname) {
      this.filedocument = [];
      this.tradegeckoId = ids;
      this.editAddress(ids);
      this.updateAddressModal = true;
      this.currentStep = 1;
      this.nickname_edit = nickname;
    },

    async openOptOut() {
      await this.editAddress(this.tradegeckoId);
      this.openOptOutForm();
    },

    openOptOutForm() {
      this.optout.company = this.editadd.data.company.name;
      this.optout.fein = this.editadd.data.company.fein;
      this.optout.company_address = this.editadd.data.address.address1;
      this.optout.company_city_state_zip =
        this.editadd.data.address.city +
        ", " +
        this.editadd.data.address.state +
        " " +
        this.editadd.data.address.zip_code;
      this.optout.role_at_company =
        localStorage.getItem("email") == this.editadd.data.company.email
          ? "Owner"
          : "Manager";
      this.showOptOutForm = true;
    },

    taxOptOutForm() {
      this.isOptoutSubmit = true;
      this.v$.optout.$touch();
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!this.v$.optout.$invalid && !isEmpty) {
        this.axios.authApi
          .post("/account/tax-optout-license", {
            signed_image: data,
            address_id: this.editadd.data.address.id,
            company_name: this.optout.company,
            company_fein: this.optout.fein,
            address1: this.optout.company_address,
            city_state_zip: this.optout.company_city_state_zip,
            role_at_company: this.optout.role_at_company,
            taxpayer_name: this.optout.taxpayer_name,
            state_tax_license_number: this.optout.state_tax_license_number,
          })
          .then((resp) => {
            if (resp.data.status) {
              this.showOptOutForm = false;
              this.isOptoutSubmit = false;
              this.showTaxDownloadOptout = true;
              this.optoutFormURL = resp.data.msg;
              this.getAddress();
            } else {
              console.log("response", resp);
            }
          });
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    downloadOptoutForm() {
      window.open(this.optoutFormURL);
    },
    selectZipcode(state) {
      this.state_code = state;
      this.getZipcodes(state);
      this.address.zip_code = "";
    },
    storeAddress() {
      let state =
        typeof this.address.state == "string"
          ? this.address.state
          : this.address.state.state;
      let zip_code =
        typeof this.address.zip_code == "string"
          ? this.address.zip_code
          : this.address.zip_code.zip_code;
      this.axios.authApi
        .post("/account/create-address", {
          address1: this.address.address1,
          address2: this.address.address2,
          city: this.address.city,
          nickname: this.address.nickname,
          state: state,
          zip_code: zip_code,
          label: "Shipping",
          ltl: this.address.ltl,
        })
        .then((resp) => {
          if (resp.status) {
            this.address.data = resp.data;
            this.address.address_id = this.address.data.data.address.id;
            this.address.licenses = this.address.data.data.licenses;
            this.tradegeckoId = this.address.data.data.address.trade_gecko_id;
            this.getAddress();

            if (this.address.licenses.length == 0) {
              this.currentStep = 1.2;
            } else {
              this.currentStep = 2;
            }
            this.isSubmit = false;
            this.reset();
            this.isDisabled = false;
            this.showConfirmDialouge = false;
          } else {
            this.address.error = resp.data.error;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      console.log(this.address);
      console.log(this.v$.address);
      console.log(this.v$.address.$invalid);
      this.isSubmit = true;
      if (this.isManualAddress) {
        this.v$.address.$touch();
      }
      if (!this.v$.address.$invalid) {
        let state =
          typeof this.address.state == "string"
            ? this.address.state
            : this.address.state.state;
        let zip_code =
          typeof this.address.zip_code == "string"
            ? this.address.zip_code
            : this.address.zip_code.zip_code;
        fetch(
          "https://us-street.api.smartystreets.com/street-address?street=" +
            this.address.address1 +
            "&city=" +
            this.address.city +
            "&state=" +
            state +
            "&zipCode=" +
            zip_code +
            "&key=115379242330163169&candidates=10&match=invalid"
        )
          .then((response) => response.json())
          .then((data) => {
            this.totalVuePackages = data[0].metadata.rdi;
            if (this.totalVuePackages != "Commercial") {
              this.showConfirmDialouge = true;
            } else {
              this.storeAddress();
            }
          });
      }
    },
    selectAddress(add) {
      this.address.address1 = add.streetLine;
      this.address.address2 =
        add.secondary && add.secondary !== "#" ? ` ${add.secondary}` : "";
      this.address.city = add.city;
      this.address.state = add.state;
      this.address.zip_code = add.zipcode;

      this.visibility = true;
      let state = this.st.data.find((s) => s.state == add.state);
      this.selectedState = state ? state.state_name : "";

      this.getZipcodes(add.state);
    },
    getMessage(loc){
      if(loc.remaining_days < 0){
        return "License Expired";
      }else if(loc.remaining_days == 0){
        return "Liecese Expires Today";
      }else if(loc.remaining_days == 1){
        return "License Expires Tomorrow";
      } else {
        let d = parseInt(loc.remaining_days) + 1;
        return `License Expires in ${d} days`;
      }
    }
  },
  setup() {
    // let query = ref('')
    // let filteredPeople = computed(() =>
    //   query.value === ''
    //     ? people
    //     : people.filter((person) =>
    //         person.name
    //           .toLowerCase()
    //           .replace(/\s+/g, '')
    //           .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    //       )
    // )
    const query = ref("");
    const axios = inject("axios");
    const address = reactive({
      loading: false,
      data: [],
      error: "",
      address_id: "",
      licenses: [],
    });
    const {
      editadd,
      getAddress,
      addresses,
      st,
      getStates,
      zipcode,
      getZipcodes,
      addLicense,
      Licenses,
      addressId,
      editAddress,
      deleteAddress,
      queryAutocompleteForSuggestion,
    } = Account();
    getAddress();
    getStates();
    const showAddressModal = ref(false);
    const showTaxStep = ref(false);
    const showTaxDownload = ref(false);
    const showTaxDownloadOptout = ref(false);
    const updateAddressModal = ref(false);
    const previewFileModal = ref(false);
    const previewEditFileModal = ref(false);
    const updatesubmit = ref(false);
    const currentStep = ref(1);
    const isManualAddress = ref(false);
    const showOptOutForm = ref(false);

    watch(query, (q) => {
      if (q.length > 2) {
        queryAutocompleteForSuggestion(q);
      }
    });

    return {
      updatesubmit,
      query,
      editadd,
      addresses,
      getAddress,
      deleteAddress,
      editAddress,
      Licenses,
      addressId,
      addLicense,
      st,
      getStates,
      zipcode,
      getZipcodes,
      address,
      showAddressModal,
      updateAddressModal,
      previewEditFileModal,
      previewFileModal,
      currentStep,
      axios,
      isManualAddress,
      showTaxStep,
      showTaxDownload,
      showTaxDownloadOptout,
      showOptOutForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.options-btn {
  @apply text-theme-body-light opacity-60 hover:opacity-100 focus:outline-none focus:ring-transparent;
}

.options-list {
  @apply flex flex-col origin-top-right absolute -mt-4 top-full right-4 w-32 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-10 divide-y divide-gray-100;

  .options-item {
    @apply block text-center text-xs text-theme-dark text-opacity-70 font-normal leading-4 px-3 py-2.5 hover:bg-gray-50;
  }
}
.card-btn {
  @apply text-sm font-medium text-theme-body hover:text-theme-secondary inline-flex items-center uppercase tracking-wide leading-none;
  svg {
    @apply w-4 h-4;
  }
}
.wd-50 {
  width: 50rem;
}

.wd-64 {
  width: 64rem;
}

.confirm-checkbox {
  @apply flex items-start space-x-2 select-none cursor-pointer;
  input {
    @apply flex-shrink-0 h-4 w-4 text-theme-secondary focus:ring-0  border-2 border-theme-border rounded focus:outline-none focus:ring-theme-primary;
    &:checked {
      @apply border-theme-secondary;
    }
  }
  span {
    @apply text-theme-dark font-light text-sm leading-snug;
  }
}

.modal {
  ::v-deep .input {
    input {
      height: 3.2rem;
    }
  }
  input:not([type="checkbox"]) {
    margin-top: 0.1rem;
    border: 2px solid #e9e9e9;
    border-radius: 0.25rem;
    height: 3.2rem;
  }
  .ss-btn {
    border: 2px solid #e9e9e9;
    border-radius: 0.25rem;
    @apply px-2 #{!important};
  }
}
.store-switcher {
  @apply relative w-full inline-block text-left mt-0;

  .ss-btn {
    @apply w-full px-6 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent;
  }

  .item-opts {
    @apply z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none;

    .item-opt {
      @apply w-full px-4 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent cursor-pointer;
    }

    .name {
      @apply text-brand-secondary text-sm font-medium truncate;
    }

    .place {
      @apply text-brand-body text-xs truncate;
    }
  }
}

.form-scroll {
  @apply w-full overflow-y-auto pb-2 -mr-3 pr-3;
  max-height: 65vh;
  @include CssScrollbar();
}
.ltl-checkbox {
  @apply flex items-center space-x-2 select-none cursor-pointer;
  input {
    @apply h-4 w-4 text-theme-indigo border-2 border-theme-indigo rounded-sm focus:ring-0 -mt-0.5;
  }
  span {
    @apply font-medium text-base text-theme-body leading-none;
  }
}
</style>
