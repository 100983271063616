<script setup>

import { watch } from 'vue';
const props = defineProps({
  name: {
    type: String,
  },
  variantType: {
    type: String,
  },
  watchlist: {
    type: null,
  },
});

watch(
  () => props.watchlist,
  (newVal) => {
    console.log('watchlist updated:', newVal);
  },
  { deep: true }
)
defineEmits(["onWatchlistUpdate"]);
</script>
<template>
  <td>
    <div class="w-full flex flex-col leading-none items-start" v-if="name">
      <div class="flex flex-col group" @click="$emit('onProductInfoTrigger')">
        <p class="text-sm text-theme-secondary cursor-pointer group-hover:underline">{{ name }}</p>
        <span class="text-xs text-theme-light uppercase flex-shrink-0" v-if="variantType">{{ variantType }}</span>
      </div>
      <button
        class="mt-2 flex items-center justify-start cursor-pointer gap-1 text-theme-light hover:text-theme-secondary flex-shrink-0"
        @click="$emit('onWatchlistUpdate')"
      >
        <Icon class="!w-3 !h-3" :name="watchlist ? 'heart-outline' : 'heart-filled'" />
        <span class="text-xs flex-shrink-0 ">
          {{ watchlist ? "Remove from Favorites" : "Add to Favorites" }}
        </span>
      </button>
    </div>
  </td>
</template>
