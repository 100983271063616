<template>
  <div class="navbar" :class="{ limited: isLimited }">
    <div class="navbar-logo" v-if="!isLimited">
      <button
        type="button"
        class="border-r border-gray-200 pr-1.5 mr-1.5 text-gray-500 focus:outline-none focus:ring-0 md:hidden"
        @click="sidebarOpen = true"
      >
        <MenuAlt2Icon class="h-5 w-5" aria-hidden="true" />
      </button>
      <router-link to="/home">
        <!-- <img v-if="logoUrl == ''" src="/images/logos/medusa-halloween.svg" class="h-7 w-auto" /> -->
        <img :src="logoUrl.url" class="h-5 sm:h-6 w-auto" />
      </router-link>
    </div>
    <div class="navbar-content">
      <div class="navbar-content-container" style="justify-content: space-between">
        <div class="flex-1">
          <img :src="logoUrl.url" class="h-5 sm:h-6 w-auto" v-if="isLimited" />
          <SearchPanel class="hidden lg:block" :price_list="cart.price_list_id" v-if="search && !isLimited && !isDistributor()" />
        </div>
        <div v-if="isLoggedIn()" class="navbar-content-items" :class="isLimited ? 'gap-2' : ''">
          <!-- Notifications -->
          <Popover as="div" class="coins-dropdown" @click="toggleCart('close')" v-if="cart.canApplyPoints == true && search && !isLimited">
            <PopoverButton class="coins-btn">
              <span>
                <Icon name="star-filled" />
              </span>
              <p>{{ commaFormat(getTotalRewards) }}</p>
            </PopoverButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 sm:scale-95"
              enter-to-class="transform opacity-100 sm:scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 sm:scale-100"
              leave-to-class="transform opacity-0 sm:scale-95"
            >
              <PopoverPanel class="coins-panel">
                <div class="panel-header">
                  <div class="coins-balance">
                    <span class="icon">
                      <Icon name="star-filled" />
                    </span>
                    <p>{{ commaFormat(getTotalRewards) }}</p>
                  </div>
                  <p>Medusa Coins</p>
                  <span class="help">
                    <a href="http://support.medusadistribution.com/en/articles/1753799-medusa-coins" target="_blank">
                      <Icon name="help" />
                    </a>
                  </span>
                </div>
                <div class="panel-content">
                  <div class="banner" v-if="unlock_reward(coin.data.points) != 0">
                    {{ commaFormat(unlock_reward(coin.data.points)) }} coins to reach the next reward
                  </div>
                  <div class="rewards">
                    <div class="progress-bar">
                      <div class="filled" :style="`height:${getTotalPercentage}%`"></div>
                    </div>
                    <div
                      class="reward"
                      :class="getTotalRewards >= point.points ? 'filled' : ''"
                      v-for="(point, idx) in coin.data.points"
                      :key="point.discount"
                    >
                      <div class="current-reward-progress">
                        <div class="cp-filled" :style="`height:${getRangeFillPercentage(idx)}%`"></div>
                      </div>
                      <div class="check-item">
                        <div class="filled" :style="`height:${getFillPercentage(point.points, idx)}%`"></div>
                        <Icon
                          class="w-4 h-4"
                          :class="getFillPercentage(point.points, idx) > 40 ? 'highlighted' : ''"
                          :name="getTotalRewards >= point.points ? 'check-filled' : 'bell'"
                        />
                      </div>
                      <div>
                        <p>${{ commaFormat(point.discount) }} OFF</p>
                        <span>{{ commaFormat(point.points) }} Coins</span>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>

          <template v-if="isLoggedIn && isLimited">
            <router-link
              to="/favorites"
              exact-active-class="active"
              class="flex items-center space-x-1.5 text-sm text-brand-body mr-4 hover:text-brand-secondary"
            >
              <Icon class="w-5 h-5" name="watchlist" />
              <span>Favorites</span>
            </router-link>
            <AccountSwitch size="small" :hideTitle="true" />
          </template>

          <!-- Notifications -->
          <Popover v-if="search" as="div" class="notify-dropdown" @click="toggleCart('close')">
            <PopoverButton class="notify-btn">
              <span class="sr-only">View notifications</span>
              <Icon name="bell" aria-hidden="true" />
            </PopoverButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 sm:scale-95"
              enter-to-class="transform opacity-100 sm:scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 sm:scale-100"
              leave-to-class="transform opacity-0 sm:scale-95"
            >
              <PopoverPanel class="notify-panel">
                <h4>Your Notifications</h4>
                <div class="notification-lists">
                  <a v-for="(notify, index) in notification.data" :key="`${notify.name}+${index}`" :href="notify.url">
                    <NotificationItem :desc="notify.description" :time="notify.created_at" :title="notify.name" />
                  </a>
                </div>
                <div class="view-all">
                  <span>CLEAR ALL</span>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>

          <!-- Profile dropdown -->
          <Menu as="div" class="profile-dropdown">
            <div>
              <MenuButton class="profile-dropdown-btn">
                <span class="initials">{{ userInitials }}</span>
                <p>{{ userName }}</p>
                <Icon name="down-arrow" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems class="profile-dropdown-items">
                <MenuItem v-for="item in filteredNavigation" :key="item.name" v-slot="{ active }">
                  <button
                    :class="[active ? 'active' : '', 'link-item']"
                    @click="
                      item.name == 'Log Out'
                        ? logout(item.name)
                        : item.name == 'Contact Rep'
                        ? onContactRepClick()
                        : $router.push(item.route)
                    "
                  >
                    <Icon :name="item.icon" />
                    <span>{{ item.name }}</span>
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>

          <a class="cart-btn" href="#" @click.prevent="toggleCart()">
            <CartCount v-if="search" />
          </a>
        </div>
        <div class="navbar-content-items" :class="search ? '' : 'justify-end flex-grow'" v-else>
          <router-link class="auth-btn login-btn" to="/login">
            <Icon name="lock" aria-hidden="true" class="h-3.5 w-3.5 -mt-0.5 md:h-4 md:w-4" />
            <span>Login</span>
          </router-link>
          <router-link class="auth-btn join-btn" to="/join-us">Join Medusa</router-link>
        </div>
      </div>
    </div>

    <div class="mobile-search" v-if="search && !isLimited && !isDistributor()">
      <SearchPanel class="m-search" />
    </div>

    <!-- Cart -->
    <Cart
      :showCart="openCart"
      @close="toggleCart()"
      :isEmpty="cart && !cart.itemsByCat?.length"
      @clear="clearCart"
      @expand="expandToggle"
      @navigate="
        () => {
          toggleCart();
          $router.push('/trending');
        }
      "
    >
      <template #header>
        <div class="price-shipping-info" :class="{ free: getFreeShippingPercentage >= 100 }" v-if="showShippingInfo">
          <button class="psi__close-btn" @click="showShippingInfo = false">
            <Icon name="close" />
          </button>
          <p class="psi__text" v-if="getFreeShippingPercentage < 100">
            <span class="psi__text--bold">${{ getFreeShippingRemaining }}</span>
            away from
            <span class="psi__text--bold">flat rate shipping!</span>
          </p>
          <p class="psi__text" v-else>
            This order qualifies for
            <span class="psi__text--bold">flat rate shipping!</span>
          </p>
          <div
            class="psi__progressBarContainer"
            :style="`--percentage: ${getFreeShippingPercentage}%`"
          >
            <div class="psi__progressBar">
              <div class="psi__progressBarRect">
                <span class="psi__progressBarCircle"></span>
              </div>
            </div>

            <span class="psi__progressBarPoint">${{ freeShippingOn.toFixed(0) }}</span>
          </div>
        </div>
      </template>
      <template #entry>
        <ConfirmRelative
          :isVisible="showClearModal"
          :isRelative="true"
          cancelButton="CANCEL"
          confirmButton="CLEAR ORDER"
          @cancel="showClearModal = false"
          @confirm="clearCart(), (showClearModal = false)"
        >
          <div class="space-y-2">
            <p class="text-sm font-light text-theme-body text-center">
              Are you sure you want to clear your cart?
            </p>
            <p class="text-sm font-normal text-theme-body text-center">
              This will remove all items from your order.
            </p>
          </div>
        </ConfirmRelative>
      </template>
      <Spinner :loading="cart_loading" />
      <!-- Cart Content Start -->
      <template v-if="!cart_loading">
        <div class="flex items-center justify-between text-sm pr-3 py-1.5">
          <button
            v-if="cart && cart.itemsByCat?.length"
            type="button"
            @click="showClearModal = true"
            class="bg-transparent text-xs font-medium uppercase text-brand-body tracking-wide hover:text-theme-dark"
          >
            Clear Cart
          </button>
          <button
            type="button"
            @click="expandToggle"
            class="bg-transparent text-xs font-medium uppercase text-brand-body tracking-wide hover:text-theme-dark"
          >
            {{ togglerText }}
          </button>
        </div>
        <Disclosure
          as="div"
          v-for="(items, ind) in cart.itemsByCat"
          :key="items.id"
          :defaultOpen="ind < 2 ? true : false"
          v-slot="{ open }"
          class="select-none"
        >
          <!-- Group Title -->
          <DisclosureButton as="div" class="cart-expander" @click="checkExpandStatus">
            <h3>{{ items.name }}</h3>
            <div class="flex-grow flex items-center truncate">
              <svg width="600" height="1" viewBox="0 0 600 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="4.37114e-08" y1="0.5" x2="600" y2="0.500026" stroke="#EAEAEA" stroke-dasharray="11 11" />
              </svg>
            </div>
            <ChevronRightIcon class="flex-shrink-0 w-5 h-5 text-brand-body" :class="open ? 'transform rotate-90' : ''" />
          </DisclosureButton>

          <!-- Group List -->
          <DisclosurePanel class="cart-expanded">
            <!-- Group Item -->
            <div
              class="cart-group-item"
              v-for="item in items.items.sort((a, b) => a.productName.localeCompare(b.productName))"
              :key="item.id"
            >
              <div
                class="cg-info cursor-pointer"
                @click="item.is_bundle == true ? $router.push(`/bundles/${item.slug}`) : $router.push(`/products/${item.slug}`)"
              >
                <img :src="item.image_url" />
                <div class="flex flex-col flex-grow sm:flex-grow-0 space-y-1.5 max-w-full">
                  <p>{{ item.productName }}</p>
                  <span class="variant">{{ item.optionLabel }}</span>
                  <span class="price">${{ currencyFormat(item.singlePrice) }}</span>
                </div>
                <p class="sm:hidden">${{ currencyFormat(item.singlePrice * item.qty) }}</p>
              </div>
              <div class="cg-amount">
                <PlusMinusInput
                  :id="item.variantId"
                  @getQuantity="updateCart($event, item.variantId)"
                  v-model:modelValue="item.qty"
                  :max="item.availableStock"
                  :min="1"
                />
                <p class="hidden sm:inline-block">${{ currencyFormat(item.singlePrice * item.qty) }}</p>
                <button
                  class="opacity-100 md:opacity-0 group-hover:opacity-100 transition cursor-pointer bg-transparent focus:outline-none"
                >
                  <Spinner className="h-5 w-5" :loading="removingItem[item.variantId]" v-if="removingItem[item.variantId]" />
                  <Icon v-else name="close" @click="removeFromCart(item.variantId, item.qty)" class="w-5 h-5 text-theme-red" />
                </button>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </template>
      <!-- Cart Footer -->
      <template #footer v-if="!cart_loading">
        <dl class="space-y-4">
          <div class="-mx-8 border-t border-gray-200"></div>
          <!-- <div v-if="cart.taxTotal" class="flex items-center justify-between">
            <dt class="text-base font-normal text-brand-primary">
              Tax
            </dt>
            <dd class="text-base font-normal text-brand-primary">
              ${{ commaFormat(Number(cart.taxTotal).toFixed(2)) }}
            </dd>
          </div>-->
          <div v-if="cart.bulkDiscount" class="flex items-center justify-between">
            <dt class="text-base font-normal text-brand-primary">
              Bulk Price Savings
            </dt>
            <dd class="text-base font-normal text-brand-primary">${{ currencyFormat(cart.bulkDiscount) }}</dd>
          </div>
          <!-- <div class="flex items-center justify-between">
            <dt class="text-base font-normal text-brand-primary">
              Order Total
            </dt>
            <dd class="text-base font-normal text-brand-primary">
              ${{ commaFormat(Number(cart.subTotal).toFixed(2)) }}
            </dd>
          </div>-->
          <!-- Coins -->
          <div
            v-if="cart.coins_saving != null && cart.coins_saving.saving && cart.coins_saving.saving > 0 && cart.canApplyPoints == true"
            class="min-w-full flex items-center justify-between bg-theme-primary-light -mx-8 px-8 py-2 space-x-3"
          >
            <div class="flex flex-col sm:flex-row space-x-3">
              <div class="flex items-center space-x-3">
                <Icon name="medusa-icon-shaded" class="w-7 h-7" />
                <p class="text-sm sm:text-base font-medium text-theme-secondary text-opacity-90">
                  Use Medusa Coins
                </p>
              </div>
              <div class="flex items-center space-x-3 pl-8 sm:pl-0">
                <span
                  class="text-tiny uppercase text-theme-secondary font-medium opacity-60 border border-theme-secondary border-opacity-40 rounded px-1"
                  >USE {{ commaFormat(cart.coins_saving.points) }} Coins</span
                >
                <tippy interactive>
                  <span class="cursor-pointer">
                    <Icon name="info" class="w-5 h-5 text-theme-secondary font-medium opacity-60" />
                  </span>
                  <template #content>
                    <p class="mb-2">
                      You can save up to ${{ commaFormat(Number(cart.coins_saving.saving).toFixed(2)) }}
                      on this order at checkout using your Medusa Coins
                    </p>
                    <p>
                      However, at checkout, you will have the option to not use any Medusa Coins.
                    </p>
                  </template>
                </tippy>
              </div>
            </div>
            <!-- <p
              class="text-base font-medium text-theme-secondary text-opacity-80 uppercase"
            >
              -{{ commaFormat(Number(cart.coins_saving.saving).toFixed(2)) }}
            </p>-->
          </div>

          <div class="flex items-center justify-between">
            <dt class="text-base font-medium text-brand-primary">
              Order Total
            </dt>
            <dd class="text-base font-medium text-brand-primary">
              <!-- ${{ commaFormat(Number(cart.subTotal).toFixed(2)) }} -->
              ${{ commaFormat(getOrderTotal(cart.itemsByCat).toFixed(2)) }}
            </dd>
          </div>
        </dl>

        <div class="mt-6">
          <p v-if="cart.shipping_id == null || shipping.state == ''" class="font-medium text-center">
            A shipping address must be selected before you can checkout
          </p>
          <Button
            title="Checkout"
            class="font-medium"
            :disabled="cart.shipping_id == null || shipping.state == '' ? true : false"
            @click="$router.push('/checkout')"
          />
        </div>
      </template>
      <!-- Cart Content End -->
    </Cart>
    <!-- Comfirmation Modal -->

    <TransitionRoot as="div" class="relative z-40 md:hidden" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 overflow-hidden z-50 font-default" style="z-index:99999;">
        <TransitionChild
          as="div"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-theme-secondary bg-opacity-20 transition-opacity z-20" @click="sidebarOpen = false" />
        </TransitionChild>
        <div class="fixed inset-0 flex z-40">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="sidebarOpen = false"
              >
                <span class="sr-only">Close sidebar</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <Sidebar @close="sidebarOpen = false" :isMobile="sidebarOpen" class="on-mobile" :isLimited="isLimited" />
          </TransitionChild>
          <div class="flex-shrink-0 flex-grow" aria-hidden="true" @click="sidebarOpen = false">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <Modal :show="confrimModal" @close="confrimModal = false">
      <div class="add-payment max-w-sm bg-white mx-auto">
        <div class="flex items-center justify-between px-5 py-4 bg-brand-bg">
          <h3 class="text-black text-lg font-semibold">Are you sure?</h3>
          <span class="cursor-pointer text-theme-body" @click="confrimModal = false">
            <Icon name="close" />
          </span>
        </div>
        <div class="flex flex-col items-start text-left space-y-4 p-5">
          <div class="w-full space-y-1">
            <p>Do you really want to clear your cart ?</p>
          </div>
        </div>
        <div class="flex items-center justify-between gap-3 px-5 py-4">
          <Button title="No" @click="confrimModal = false" />
          <Button title="Yes" @click="clear" />
        </div>
      </div>
    </Modal>
    <ModalFull :is-visible="tourModal" :reset-container="true" container-class="w-full max-w-2xl" @close="tourModal = false">
      <WelcomeTour @close="tourModal = false" />
    </ModalFull>

    <ModalFull :is-visible="expiryModal" :reset-container="true" @close="expiryModal = false">
      <ExpiryNotification @close="expiryModal = false" :msg="expiryMsg" :titleMsg="titleMsg" />
    </ModalFull>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import NotificationItem from "./NotificationItem.vue";
import Auth from "@/modules/Auth";
import AccountSummary from "@/modules/AccountSummary";
import Notifications from "@/modules/Notifications";
import Cart from "@/components/Cart.vue";
import Sidebar from "./Sidebar.vue";
import WelcomeTour from "@/components/WelcomeTour.vue";
import ConfirmRelative from "@/components/ConfirmRelative.vue";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  TransitionChild,
  TransitionRoot,
  DialogOverlay,
} from "@headlessui/vue";

import { ref, computed, nextTick, watch } from "vue";
import SearchPanel from "./SearchPanel.vue";
import CartCount from "./CartCount.vue";
import { inject, onMounted } from "vue";
import debounce from "lodash/debounce";
import algoliasearch from "algoliasearch/lite";
import { ChevronRightIcon, MenuAlt2Icon, XIcon } from "@heroicons/vue/outline";
import vClickOutside from "click-outside-vue3";
import { useStore } from "vuex";
import axios from "axios";
import ExpiryNotification from "@/components/ExpiryNotification.vue";
import AccountSwitch from "./AccountSwitch.vue";

const userNavigation = [
  {
    name: "Orders",
    icon: "package",
    route: "/account",
  },
  {
    name: "Locations",
    icon: "shop",
    route: "/account/stores",
  },
  {
    name: "Payment",
    icon: "credit-card",
    route: "/account/payments",
  },
  {
    name: "Users",
    icon: "user-account",
    route: "/account/users",
  },
  {
    name: "Contact Rep",
    icon: "message",
    route: "/",
  },
  {
    name: "Log Out",
    icon: "logout",
    route: "/login",
  },
];

export default {
  name: "Navbar",
  mixins: [Mymixin],
  props: {
    search: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchClient: algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY),
      removingItem: [],
      prevCart: [],
      logoUrl: "",
      freeShippingOn: 2000,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    NotificationItem,
    Popover,
    PopoverButton,
    PopoverPanel,
    CartCount,
    Cart,
    SearchPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
    Sidebar,
    Dialog,
    TransitionChild,
    TransitionRoot,
    DialogOverlay,
    MenuAlt2Icon,
    XIcon,
    WelcomeTour,
    ConfirmRelative,
    ExpiryNotification,
    AccountSwitch,
  },
  mounted() {
    axios.get(process.env.BASE_URL_API + "/logo/get").then((res) => {
      this.logoUrl = res.data;
    });

    let interval = setInterval(() => {
      if (this.$store.state.cart.loading == false) {
        this.prevCart = this.$store.state.cart.cart;
        clearInterval(interval);
      }
    }, 100);
    if (this.routeFromCheckout) {
      this.toggleCart();
      this.$store.dispatch("setFromCheckOut", false);
    }
  },

  updated() {
    let total_current_items = 0;
    let total_current_qty = 0;

    let total_previous_items = 0;
    let total_previous_qty = 0;
    this.prevCart?.itemsByCat?.forEach((element) => {
      element.items.forEach((item) => {
        total_previous_items++;
        total_previous_qty += item.qty;
      });
    });

    this.cart?.itemsByCat?.forEach((element) => {
      element.items.forEach((item) => {
        total_current_items++;
        total_current_qty += item.qty;
      });
    });

    if (total_current_items > total_previous_items || total_current_qty > total_previous_qty) {
      this.prevCart = this.cart;
    }
  },

  methods: {
    // Update Cart Data in Store
    updateCart: debounce(function(d, variant_id) {
      let preQty = 0;

      if (this.isLoggedIn) {
        this.prevCart.itemsByCat.forEach((element) => {
          element.items.forEach((item) => {
            if (item.variantId == variant_id) {
              preQty = item.qty;
              item.qty = d[0];
            }
          });
        });
      }

      let segmentQtyAdded = localStorage.getItem("segmentQty") || 0;

      let [qty, add] = d;
      this.$store.dispatch("UpdateCart", {
        variant_id: variant_id,
        qty: qty,
        segmentQty: (add ? qty - preQty : preQty - qty) + parseInt(segmentQtyAdded),
        axios: this.axios,
        route: this.$route,
        add,
      });

      localStorage.setItem("segmentQty", (add ? qty - preQty : preQty - qty) + parseInt(segmentQtyAdded));
    }, 200),

    getOrderTotal(data) {
      let temp = 0;
      if (data) data.forEach((e) => e.items.forEach((i) => (temp += i.qty * i.singlePrice)));
      return temp;
    },

    removeFromCart(variant_id, qty) {
      this.removingItem[variant_id] = true;
      this.$store.dispatch("removeFromCart", {
        variant_id: variant_id,
        qty: qty,
        segmentQty: qty,
        axios: this.axios,
        route: this.$route,
      });
      setInterval(() => (this.removingItem[variant_id] = false), 1000);
    },
    clearCart() {
      this.$store.dispatch("clearCart", {
        axios: this.axios,
        route: this.$route,
      });
    },
    onClickOutside() {
      this.showSearch = false;
    },
    getSearch: function(val) {
      if (val.length > 0) {
        this.showSearch = true;
      }

      if (val === true) {
        this.showSearch = true;
      }

      if (val === false || val === "") {
        this.showSearch = false;
      }
    },
    /*     coinss(coins){
      if(coins){
        let arr = Object.keys(coins).map(key => coins[key]);
        console.log(arr.length)
        for (let i = 0; i < arr.length ; i++) {
          if(arr[i].disabled == true){
            return arr[i].points;
          }  
        }
      }
      return 0;
    }, */
    unlock_reward(coins) {
      if (coins) {
        let count = 0;
        let arr = Object.keys(coins).map((key) => coins[key]);
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].points > this.getTotalRewards) {
            count = arr[i].points - this.getTotalRewards;
            return count;
          }
        }
      }
      return 0;
    },
  },
  computed: {
    cart() {
      // console.log(this.$store.getters.cart);
      return this.$store.getters.cart;
    },
    routeFromCheckout() {
      return this.$store.getters.getFromCheckOut;
    },
    cart_loading() {
      return this.$store.getters.loading;
    },
    shipping() {
      return this.$store.getters.getSelectedAddress;
    },
    getFreeShippingRemaining() {
      return (this.freeShippingOn - this.getOrderTotal(this.cart.itemsByCat)).toFixed(2);
    },
    getFreeShippingPercentage() {
      const perc = (this.getOrderTotal(this.cart.itemsByCat) / this.freeShippingOn) * 100;
      return perc >= 100 ? 100 : perc;
    },
    showShippingInfo() {
      return this.getFreeShippingPercentage > 60;
    },
    filteredNavigation() {
      // Payment option should not be shown if the user is not from USA
      return this.userNavigation.filter((item) => !(item.name === "Payment" && this.cart?.IsUSA == 0));
    },
  },
  setup(props) {
    const axios = inject("axios");
    const store = useStore();

    const userName = localStorage.getItem("username") || "";

    const { isLoggedIn, isDistributor, loggedOut } = Auth();

    const openCart = ref(false);

    const sidebarOpen = ref(false);

    const showSearch = ref(false);

    const confrimModal = ref(false);

    const togglerText = ref("Expand All");

    const tourModal = ref(false);

    const expiryModal = ref(false);

    const expiryMsg = ref("");

    const titleMsg = ref("");

    const showClearModal = ref(false);

    const userInitials = computed(() => {
      return userName
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    });

    onMounted(() => {
      if (localStorage.getItem("last_login") == "") {
        tourModal.value = true;
        localStorage.setItem("last_login", new Date());
      }
    });

    // Access addresses from store
    const addresses = computed(() => store.getters.getAddresses);

    // Compute expired addresses
    const expiredAddresses = computed(() => {
      return addresses.value.filter((address) => {
        return address.is_expiring === true;
      });
    });

    // Watch for changes in addresses
    let count = 0;
    watch(addresses, (newAddresses) => {
      if (newAddresses.length > 0 && count === 0) {
        count++;
        checkExpiredAddresses();
      }
    });

    // Method to check if any address is expired
    const checkExpiredAddresses = () => {
      if (expiredAddresses.value.length > 0) {
        expiredAddresses.value.some((address) => {
          titleMsg.value = "Your license for " + address.state + " is expiring soon.";
          if (address.remaining_days < 0) {
            expiryMsg.value = `Your ${address.licenses[0].document_type} has expired. Upload your updated license to avoid further delays.`;
            titleMsg.value = "Your license for " + address.state + " has expired.";
            return true; // Stop iteration
          } else {
            let days = `in ${parseInt(address.remaining_days) + 1} days`;
            if (address.remaining_days === 0) {
              days = "today";
            } else if (address.remaining_days === 1) {
              days = "tomorrow";
            }
            expiryMsg.value = `Your ${address.licenses[0].document_type} expires ${days}. Upload your updated license to avoid delays.`;
          }
        });
        expiryModal.value = true;
      }
    };

    function logout(nav) {
      if (nav === "Log Out") {
        loggedOut();
      }
    }

    const { summary, coins, coin, medusaCoins } = AccountSummary();
    coins();
    medusaCoins();

    const { notification, getNotifications } = Notifications();
    getNotifications();

    const toggleCart = (status) => {
      if (status === "close") {
        openCart.value = false;
      } else {
        if (!openCart.value) {
          store.dispatch("getCart", { ax: axios });
        }
        openCart.value = !openCart.value;
      }
      checkExpandStatus();
    };

    const expandToggle = () => {
      const selector = togglerText.value == "Expand All" ? ".cart-expander[aria-expanded='false']" : ".cart-expander[aria-expanded='true']";
      const itemsToggler = document.querySelectorAll(selector);
      [...itemsToggler].forEach((el) => el.click());
      checkExpandStatus();
    };

    const checkExpandStatus = () => {
      setTimeout(() => {
        nextTick(() => {
          const totalTogglers = document.querySelectorAll(".cart-expander");
          const collapsedItems = document.querySelectorAll(".cart-expander[aria-expanded='true']");
          if (totalTogglers.length == collapsedItems.length) {
            togglerText.value = "Collapse All";
          } else {
            togglerText.value = "Expand All";
          }
        });
      }, 100);
    };

    const getTotalRewards = computed(() => summary.coin_balance || 0);

    const getTotalPercentage = computed(() => ((getTotalRewards.value / 37500) * 100).toFixed(0));

    const getFillPercentage = (coins, idx) => {
      let perc = ((getTotalRewards.value / coins) * 100).toFixed(0);
      if (idx == 1 && getTotalPercentage.value <= 25) return perc;
      return 0;
    };

    const getRangeFillPercentage = (idx) => {
      const rewardPoints = Object.values((coin.data && coin.data.points) || {}).map((p) => p.points);
      const index = parseInt(idx);
      return ((getTotalRewards.value / rewardPoints[index]) * 100).toFixed(0);
    };

    const onContactRepClick = () => {
      document.querySelector("#rep-panel button")?.click();
    };

    const isLimited = computed(() => props.type === "limited");

    return {
      tourModal,
      userNavigation,
      userName,
      isLoggedIn,
      summary,
      confrimModal,
      notification,
      openCart,
      logout,
      showSearch,
      axios,
      toggleCart,
      medusaCoins,
      getTotalRewards,
      getTotalPercentage,
      coin,
      userInitials,
      sidebarOpen,
      getFillPercentage,
      getRangeFillPercentage,
      togglerText,
      expandToggle,
      checkExpandStatus,
      showClearModal,
      expiryModal,
      onContactRepClick,
      expiryMsg,
      titleMsg,
      isLimited,
      isDistributor
    };
  },
};
</script>

<style lang="scss">
.navbar {
  @apply flex flex-wrap justify-between fixed top-0 left-0 w-screen bg-white shadow-nav items-center py-3 z-50;

  .mobile-search {
    @apply lg:hidden w-full px-5 pt-3 mt-3 border-t border-gray-100;
  }
  &-logo {
    @apply w-auto lg:w-72 pl-2 pr-3 sm:px-6 flex items-center justify-start lg:justify-center z-50;
  }
  &-content {
    @apply flex-grow pr-3 sm:px-5 md:px-10 z-50;
    &-container {
      @apply w-full flex justify-end lg:justify-between items-center;
      @include maxContainer();
      .search-bar {
        @apply max-w-xl w-full relative;
      }
    }
    &-items {
      @apply flex items-center space-x-1.5  md:space-x-4 ml-2 md:ml-6 flex-shrink-0;
    }
    .coins-btn {
      @apply flex items-center space-x-2 bg-opacity-10 rounded-full py-1.5 px-1.5 sm:pl-2 sm:pr-3 border-none focus:outline-none;
      background: rgba(189, 150, 111, 0.25);
      span {
        @apply w-4 h-4 sm:w-5 sm:h-5 rounded-full flex items-center justify-center;
        background: #bd966f;
        svg {
          @apply w-3.5 h-3.5 text-white;
        }
      }
      p {
        @apply hidden sm:block text-black text-xs sm:text-sm font-medium leading-none;
      }
    }
    .coins-dropdown {
      @apply flex items-center relative;

      .coins-panel {
        top: 56px;
        @apply sm:origin-top-right fixed sm:absolute right-0 sm:mt-2 left-0 sm:left-auto w-screen sm:w-80 sm:rounded-lg shadow-nav bg-white ring-1 ring-black ring-opacity-5 focus:outline-none sm:top-full z-50 bottom-0 sm:bottom-auto;

        .panel-header {
          @apply w-full flex flex-col items-center justify-center px-6 py-4 border-b border-gray-100 relative;

          .coins-balance {
            @apply flex items-center justify-center space-x-2;
            .icon {
              @apply w-8 h-8 rounded-full flex items-center justify-center;
              background: #eee5db;
              svg {
                @apply w-5 h-5 text-theme-primary;
              }
            }
            p {
              @apply text-2xl font-semibold;
              color: #42372c;
            }
          }

          > p {
            @apply text-lg mt-2;
            color: #828a9e;
          }

          .help {
            @apply absolute right-5 top-5 text-theme-body opacity-75 hover:opacity-100 cursor-pointer;

            svg {
              @apply w-5 h-5;
            }
          }
        }

        .panel-content {
          @apply relative w-full;

          .banner {
            @apply px-4 py-2.5 text-xxs text-center;
            background: #bd966f0f;
            color: #70715d;
          }

          .rewards {
            @apply relative px-6 py-5;

            .progress-bar {
              @apply absolute top-9 left-14 -ml-1 w-2 bottom-8 z-0 rounded-lg overflow-hidden;
              background: #f1f1f1;

              .filled {
                @apply bg-theme-primary absolute inset-x-0 top-0 rounded-lg;
              }
            }

            .current-reward-progress {
              @apply absolute top-9 left-8 -ml-1 w-2 -bottom-4 z-0 rounded-lg overflow-hidden;

              .cp-filled {
                @apply opacity-0 bg-theme-primary absolute inset-x-0 top-0 rounded-lg;
              }
            }

            .reward {
              @apply relative w-full flex items-center py-3 space-x-3  justify-start;

              .check-item {
                @apply relative flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-full mr-3 z-20 overflow-hidden;
                background: #f1f1f1;

                .filled {
                  @apply bg-theme-primary absolute inset-x-0 top-0;
                }

                svg {
                  @apply w-5 h-5 text-theme-dark text-opacity-60 relative;

                  &.highlighted {
                    @apply text-white;
                  }
                }
              }

              .info {
                @apply flex flex-col items-start space-y-1 text-theme-body;

                span {
                  @apply text-sm;
                }
              }

              p {
                @apply text-base font-semibold leading-none;
                color: #42372c;
              }

              span {
                @apply text-base font-medium text-theme-body;
              }

              &.filled {
                &:before {
                  @apply bg-theme-primary;
                }
                .check-item {
                  @apply bg-theme-primary;
                  svg {
                    @apply text-white;
                  }
                }
                .current-reward-progress .cp-filled {
                  @apply opacity-100;
                }
              }
            }
          }
        }
      }
    }
    .notify-dropdown {
      @apply flex items-center relative;
      .notify-btn {
        @apply bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none;
        svg {
          @apply h-6 w-6;
        }
      }
      .notify-panel {
        top: 56px;
        @apply sm:origin-top-right fixed sm:absolute right-0 sm:mt-2 left-0 sm:left-auto w-screen sm:w-96 sm:rounded-lg shadow-nav bg-white ring-1 ring-black ring-opacity-5 focus:outline-none sm:top-full z-50 h-full sm:h-auto;

        h4 {
          @apply block text-xs text-theme-light-shade leading-none px-5 py-3 uppercase;
        }
        .notification-lists {
          @apply flex flex-col divide-y divide-gray-100 overflow-y-auto;
          max-height: calc(100vh - 137px);
          @media (min-width: 640px) {
            max-height: 70vh;
          }
          @include CssScrollbar();
        }
        .view-all {
          @apply block text-center bg-theme-bg-light font-medium text-theme-body hover:text-theme-dark-shade px-5 py-3 text-sm uppercase w-full cursor-pointer;
        }
      }
    }
    .profile {
      &-dropdown {
        @apply hidden sm:flex items-center relative;
        &-btn {
          @apply max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none flex-shrink-0;
          .initials {
            @apply flex items-center justify-center rounded-full h-6 w-6   bg-brand-indigo text-brand-indigodark font-semibold leading-none;
            font-size: 10px;
          }
          p {
            @apply text-sm text-theme-light px-2 flex-shrink-0;
          }
          svg {
            @apply h-5 w-5 text-theme-body flex-shrink-0;
          }
        }
        &-items {
          @apply origin-top-right absolute top-full right-0 mt-2 w-44 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none;
          .link-item {
            @apply flex items-center space-x-4 px-4 py-2.5 text-sm text-theme-dark w-full;
            &.active {
              @apply bg-gray-100;
            }
            svg {
              @apply w-5 h-5;
            }
          }
        }
      }
    }
    .cart-btn {
      @apply flex items-center flex-shrink-0;
      p {
        @apply text-base text-theme-light px-2 flex-shrink-0;
        span {
          @apply text-theme-dark;
        }
      }
      min-width: 55px;
      margin-right: -5px !important;
    }
    .auth-btn {
      @apply inline-flex items-center flex-shrink-0 bg-white text-black focus:outline-none leading-none space-x-0 md:space-x-1 mr-1 md:mr-4 text-xs md:text-sm;
      &.login-btn {
        @apply text-xs md:text-sm;
      }
      &.join-btn {
        @apply -my-1 border-2 border-black px-2 md:px-6 py-2 md:py-2.5 rounded-lg;
      }
    }
  }

  &.limited {
    min-height: 60px;
    .navbar-content {
      &-container {
        @apply px-8;
      }
    }
  }
}
.cart-expander {
  @apply cursor-pointer flex items-center space-x-8 overflow-hidden;
  h3 {
    @apply text-base font-medium text-theme-dark flex-shrink-0;
  }
}
.cart-expanded {
  @apply flex flex-col divide-y divide-gray-100 pt-5;
  .cart-group-item {
    @apply flex flex-col sm:flex-row items-center justify-between group py-3;
    .cg-info {
      @apply flex items-center space-x-4 flex-shrink-0 w-full sm:w-1/2 sm:pr-3;
      > img {
        @apply w-14 h-14 object-contain object-center;
      }
      p {
        @apply text-md leading-none text-theme-secondary font-medium font-default truncate;
      }
      span {
        @apply text-xs leading-none text-theme-body font-normal font-default;
        &.variant {
          @apply text-theme-dark;
        }
      }
    }
    .cg-amount {
      @apply flex items-center space-x-5 flex-shrink-0  w-full sm:w-1/2 justify-between sm:justify-end font-default pr-2 pl-16 mt-4 sm:px-0 sm:mt-0;
      p {
        @apply font-medium text-sm text-theme-secondary w-full text-right;
        max-width: 90px;
      }
    }
  }
}
.price-shipping-info {
  @apply border-t w-full;
  padding-top: 10px;
  margin-bottom: -10px;

  &.free {
    .psi__text {
      @apply text-green-500;
    }

    .psi__progressBarRect {
      background-image: linear-gradient(90deg, #65c376 0%, #a0df92 100%);
    }
  }

  .psi__close-btn {
    @apply hidden absolute right-1.5 top-1.5 text-theme-body hover:text-theme-secondary;
  }
}

.psi__text {
  @apply text-sm text-theme-secondary font-light;
}

.psi__text--xs {
  @apply text-xs;
}

.psi__text--bold {
  @apply font-medium;
}

.psi__progressBarContainer {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}

.psi__progressBar {
  @apply flex justify-start items-center rounded-full w-full bg-white border border-gray-100;
  height: 9px;
}

.psi__progressBarRect {
  background-image: linear-gradient(90deg, #c39465 0%, #dfb992 100%);
  height: 100%;
  padding: 2px;
  width: var(--percentage, 0%);
  border-radius: inherit;
  @apply flex justify-end items-center;
  animation: progressLineTransmission 2.5s 0.3s ease-in-out both;
  transition: ease width 0.5s;
}

.psi__progressBarCircle {
  @apply bg-white w-1.5 h-1.5 rounded-full;
}

.psi__progressBarPoint {
  @apply inline-block text-xs font-medium relative text-theme-secondary;
}

@keyframes progressLineTransmission {
  from {
    width: 0%;
  }

  to {
    width: var(--percentage, 0%);
  }
}
</style>
